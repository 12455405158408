import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import config from '../../config';

// #region /////// COMPONENTS ///////
import LandingLoader from '../../components/LandingLoader';
import Modal from '../../components/Modal';
import Toast from '../../components/Toast';
import AppSectionRouter from '../AppSectionRouter';
import ProfileManager from '../ProfileManager';
// #endregion

import { isDesktop } from 'react-device-detect';
import { FatalErrorBoundary } from '../../HOCs/WithErrorBoundary';
import DownloadApp from '../../components/DownloadApp';
import { HandleError } from '../../components/HandleError';
import NotificationModal from '../../components/NotificationModal';
import { MOBILE_ENABLED_PATHS } from '../../constants/enabledMobile';
import { useAnalytics } from '../../firebase';
import classNames from '../../utils/classnameUtils';
import * as hooks from './hooks';
import * as selectors from './selectors';
import useClientConfigs from '../../customHooks/useClientConfigs';
import AppStore from '../../components/AppStore';
import PATHS from '../../constants/pathRoutes';

function App({ history }) {
  const { ready } = useTranslation();
  // #region /////// SELECTORS ///////
  const accessToken = useSelector((state) =>
    selectors.selectAccessToken(state),
  );
  const error = useSelector((state) => selectors.selectError(state));
  const isFetching = useSelector((state) => selectors.selectIsFetching(state));
  const togglePorfilesModal = useSelector((state) =>
    selectors.selectToggleProfilesModal(state),
  );
  const notificationModal = useSelector((state) =>
    selectors.selectNotificationModal(state),
  );
  const appSettings = useSelector((state) =>
    selectors.selectAppSettings(state),
  );
  const { mobileAppUrls } = appSettings;
  // #endregion
  const showApp = ready && !isFetching && !!accessToken.access_token;
  const { hasPayService, hasMobileLanding, enabledResponsiveWeb } =
    useClientConfigs();

  // #region /////// HOOK EFFECTS ///////
  useAnalytics();
  hooks.useDidMount(config, accessToken);
  hooks.useProfiles(accessToken);
  hooks.useCurrentProfileValidation(accessToken);
  hooks.useUserLogout();
  hooks.useNewRelicAttributes(accessToken);
  hooks.useRatingImages(accessToken);
  hooks.useGetAccountInformation(accessToken);
  hooks.useNotifications();
  /// /#endregion

  // INFO: En caso de no tener la configuracion requerida de entorno
  // o unity devuelve error en el endpoint inicial para obtener token.
  if (!config || (error.code && error.message)) {
    return (
      <HandleError
        error={
          error?.code ? error : { error: 'FATAL: Startup configuration error.' }
        }
        navBar={false}
      />
    );
  }

  if (!showApp) return <LandingLoader />;
  if (
    hasMobileLanding &&
    !isDesktop &&
    !window.location.pathname.includes('landing') &&
    !window.location.pathname.includes('activar') &&
    !window.location.pathname.includes('login')
  ) {
    !window.location.href.endsWith('/')
      ? window.location.replace(`${window.location.href}/${PATHS.mobileLading}`)
      : window.location.replace(`${window.location.href}${PATHS.mobileLading}`);
    return null;
  } else {
    if (
      !enabledResponsiveWeb &&
      !isDesktop &&
      !MOBILE_ENABLED_PATHS.find((r) => window.location.pathname.includes(r))
    ) {
      if (hasPayService) return <DownloadApp mobileAppUrls={mobileAppUrls} />;
      else return <AppStore mobileAppUrls={mobileAppUrls} />;
    }

    return (
      <React.Fragment>
        <AppSectionRouter history={history} />
        <Toast />
        <Modal
          show={togglePorfilesModal || notificationModal}
          classNameCustom={classNames(
            togglePorfilesModal ? 'tbxContentModalProfile' : '',
            notificationModal ? 'tbxNotificationModal' : '',
          )}
        >
          {(togglePorfilesModal && <ProfileManager history={history} />) ||
            (notificationModal && (
              <NotificationModal notificationModal={notificationModal} />
            ))}
        </Modal>
      </React.Fragment>
    );
  }
}

export default FatalErrorBoundary(App);
