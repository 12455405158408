const PATHS = {
  logout: '/logout',
  login: '/login',
  search: '/search',
  active: '/activar',
  error: '/error',
  content: '/content',
  player: '/player',
  privacy: '/privacidad',
  termAndUses: '/terminos-de-uso',
  help: '/ayuda',
  mobileLading: 'landing',
};

export default PATHS;
