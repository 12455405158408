import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import { extractIframeHeight } from './utils';

const IFrameComponent = ({ source }) => {


  const extractedHeight = React.useMemo(() => {
    const height = extractIframeHeight(source);
    return height || 'auto'; 
  }, [source]);


  return(
  <div className="iframe-container">
    <iframe
      title="gamecast"
      src={`${source}`}
      width="100%"
      height={extractedHeight}
      scrolling="auto"
    ></iframe>
  </div>
);
}
IFrameComponent.propTypes = {
  source: PropTypes.string.isRequired,
};

export default IFrameComponent;
