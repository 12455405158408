import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import * as selectors from '../containers/App/selectors';

const parseScriptString = (scriptString) => {
    const scriptDetails = {};
    if (scriptString) {
        // Extract src attribute
        const srcMatch = scriptString.match(/src=['"]([^'"]+)['"]/);
        if (srcMatch) {
            scriptDetails.src = srcMatch[1];
        }

        // Extract type attribute
        const typeMatch = scriptString.match(/type=['"]([^'"]+)['"]/);
        if (typeMatch) {
            scriptDetails.type = typeMatch[1];
        }

        // Extract innerHTML
        const innerHTMLMatch = scriptString.match(/<script[^>]*>([\s\S]*?)<\/script>/);
        if (innerHTMLMatch) {
            scriptDetails.innerHTML = innerHTMLMatch[1].trim();
        }
    }

    return scriptDetails;
};

export function useScript(isEnabled) {
    const appSettings = useSelector(selectors.selectAppSettings);
    const isAuthenticated = useSelector(selectors.selectAuthenticated);
    const account = useSelector(selectors.selectAccountInformation);
    const { FRESHDESK_GROUP_ID: freshdeskGroupId  } = window.__TBX_ENV__;

    useEffect(() => {
        const widgetElement = document.getElementById('launcher-frame');
        if (!isEnabled || !appSettings?.widgetSupport) {
            if (widgetElement) {
                widgetElement.remove();
            }
            return;
        }

        if(widgetElement){
            return;
        }

        const { innerHTML, src, type } = parseScriptString(atob(appSettings.widgetSupport));

        const scriptContent = document.createElement('script');
        scriptContent.innerHTML = `${innerHTML}`;
        document.body.appendChild(scriptContent);

        const scriptTag = document.createElement('script');
        scriptTag.type = type;
        scriptTag.src = src;
        scriptTag.async = true;
        scriptTag.defer = true;
        document.body.appendChild(scriptTag);

        const cleanUpScripts = () => {
            if(isEnabled){
                return;
            }
            if (document.body.contains(scriptContent)) {
                document.body.removeChild(scriptContent);
            }
            if (document.body.contains(scriptTag)) {
                document.body.removeChild(scriptTag);
            }
            const widgetElement = document.getElementById('launcher-frame');
            if (widgetElement) {
                widgetElement.remove();
            }
        };

        return cleanUpScripts;
    }, [appSettings?.widgetSupport, isEnabled]);

    useEffect(() => {
        const userInfo = account?.info;
        if (window.FreshworksWidget && isAuthenticated && userInfo && isEnabled) {
            // Prefill customer details
            window.FreshworksWidget('identify', 'ticketForm', {
                name: `${userInfo.name} ${userInfo.lastname}`,
                email: `${userInfo.email}`,
            });
        }
    }, [account, isAuthenticated, window.FreshworksWidget, isEnabled]);

    useEffect(() => {
        if (window.FreshworksWidget && freshdeskGroupId) {
            window.FreshworksWidget('identify', 'ticketForm', {
                group_id: Number(freshdeskGroupId)
            });
            window.FreshworksWidget('hide', 'ticketForm', ['group_id']);
        }
    }, [window.FreshworksWidget, freshdeskGroupId]);
}
