import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CONTENT_TYPES,
  IMAGE_MODE,
  TRUCATE_STRING,
} from '../../../constants/contentTypes';
import { NO_IMAGE_DEFAULT } from '../../../constants/noImage';
import useRouter from '../../../customHooks/useRouter';
import useWidgetTranslation from '../../../customHooks/useWidgetTranslation';
import { findImageSettingByType } from '../../../utils/appSettingUtils';
import * as actions from './actions';
import { SHOW_RATING_IMAGE, TITLES, types } from './constants';
import * as hooks from './hooks';
import * as selectors from './selectors';

// #region /////// COMPONENTS ///////
import {
  ContentEpisodes,
  ContentOverview,
  ContentRelated,
  GenericModal,
  isTokenFromBR,
} from '@tbx/experience-widgets-lib';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { SectionErrorBoundary } from '../../../HOCs/WithErrorBoundary';
import Footer from '../../../components/Footer';
import IFrameComponent from '../../../components/IFrameComponent';
import Seo from '../../../components/Seo';
import SocialSharingList from '../../../components/SocialSharingList';
import { ERROR_CODE } from '../../../constants/errorCode';
import { RAITINGS_DEFAULT } from '../../../constants/ratings';
import {
  getSeoTitleInSection,
  validateAccessToContentByUrns,
  validateUrlRedirect,
} from '../../../utils/ContentUtils';
import { validateFavoriteContent } from '../../../utils/ProfileUtils';
import { getContentImageByMode } from '../../../utils/imageUtils';
import { truncateString } from '../../../utils/objectUtils';
import { convertPath } from '../../../utils/pathRoutesUtils';
import {
  checkifHaveContentAccess,
  openStore,
} from '../../../utils/validationUserUtils';
import { PATH_ERROR } from '../../AppSectionRouter/constants';
import {
  addFavoriteContentByProfile,
  deleteFavoriteContentByProfile,
} from '../../ProfileManager/actions';
import { selectCurrentContentPosition } from '../../ProfileManager/selectors';
import useBrandInfo from '../../../customHooks/useBrandInfo';
import { redirectToAccountInformation } from '../../Navbar/actions';
import useClientConfigs from '../../../customHooks/useClientConfigs';
import { useScript } from '../../../customHooks/useScript';
import ExtendedFooter from '../../../components/ExtendedFooter';
// #endregion

// #region /////// VARIABLES goBackFunction ///////
const redirect = {
  url: '/',
  countGoBack: 0,
  boolGoBack: false,
};
// #endregion

function ContentOverViewSection({ accessToken, storeTime }) {
  const router = useRouter();
  const { t, i18n } = useTranslation();
  const contentID = router.query.contentID;
  const mainDiv = React.useRef();
  const translationTexts = useWidgetTranslation(TITLES);
  const [showFooter, setShowFooter] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [selectedSeason, setSelectedSeason] = React.useState(1);
  const dispatch = useDispatch();
  const BRAND_INFO = useBrandInfo();
  const {
    showContentDescription,
    hasPayService,
    hasDirectAccessToAddOns,
    useExtendedFooter,
  } = useClientConfigs();

  // #region /////// SELECTORS ///////
  const selectedContent = useSelector((state) =>
    selectors.selectContentWidget(state),
  );
  const selectedContentRelated = useSelector((state) =>
    selectors.selectContentRelatedWidget(state),
  );
  const selectContentEpisodes = useSelector((state) =>
    selectors.selectContentEpisodesWidget(state),
  );
  const authenticated = useSelector((state) =>
    selectors.selectAuthenticated(state),
  );
  const appSettings = useSelector((state) =>
    selectors.selectAppSettings(state),
  );
  const redirectUrl = useSelector((state) =>
    selectors.selectContentsUrlByID(state),
  );
  const currentContentPosition = useSelector((state) =>
    selectCurrentContentPosition(state),
  );
  const selectError = useSelector((state) => selectors.selectError(state));
  const favorites = useSelector((state) =>
    selectors.selectFavoritesContents(state),
  );
  const favoritesIsFetching = useSelector((state) =>
    selectors.selectFavoritesIsFetching(state),
  );
  const PATHS = useSelector((state) => selectors.selectPathList(state));
  const selectRatingImages = useSelector((state) =>
    selectors.selectRatingImages(state),
  );
  const selectUserUrns = useSelector((state) =>
    selectors.selectUserUrns(state),
  );
  const selectTotalEpisodes = useSelector((state) =>
    selectors.selectTotalEpisodes(state),
  );
  const { mobileAppUrls } = appSettings;
  // #endregion

  // #region ///// HOOK EFFECTS /////
  useScript(true);
  hooks.useDidMount(
    accessToken,
    contentID,
    mainDiv,
    redirectUrl,
    authenticated,
    PATHS,
    selectRatingImages,
  );
  const toastAdd = hooks.useToastAdd();
  hooks.useStoreTimeOnClickBack(storeTime, redirect, router, PATHS);
  const { contentEpisodesState, currentSeason } = hooks.useBookmarkEpisodes(
    selectContentEpisodes,
    currentContentPosition,
  );
  const contentSeasonsData = selectedContent?.episodesBySeason?.map(
    ({ episodes, ...rest }) => rest,
  );
  // #endregion

  const onClickPlayerButton = (contentId) => {
    const hasRedirect = validateUrlRedirect(currentContentPosition);
    hasRedirect &&
      authenticated &&
      dispatch(actions.getContentsUrlById(accessToken, contentId));

    if (!isDesktop) {
      openStore(mobileAppUrls);
    } else if (checkifHaveContentAccess(authenticated, selectedContent)) {
      const canPlay =
        selectedContent.free || !hasPayService
          ? true
          : validateAccessToContentByUrns(selectedContent?.urn, selectUserUrns);

      if (!canPlay) {
        dispatch(
          redirectToAccountInformation(accessToken, hasDirectAccessToAddOns),
        );
      } else if (!hasRedirect && canPlay) {
        redirect.countGoBack = 0;
        const subPath =
          currentContentPosition?.bookmarkEpisodes &&
          currentContentPosition?.bookmarkEpisodes.length > 0 &&
          selectedContent?.contentType === CONTENT_TYPES.SERIE
            ? currentContentPosition?.bookmarkEpisodes[0].contentId
            : currentContentPosition
              ? currentContentPosition.currentContent.id
              : contentId;

        router.push(convertPath(PATHS.player, subPath));
      }
    } else {
      redirect.countGoBack = 0;
      const { origin } = window.location;
      const returnURL = origin + convertPath(PATHS.content, contentID);
      router.push(PATHS.login, { returnURL });
    }
  };

  const onClickEpisode = (contentId) => {
    const hasRedirect = validateUrlRedirect(currentContentPosition);
    hasRedirect &&
      authenticated &&
      dispatch(actions.getContentsUrlById(accessToken, contentId));

    if (!isDesktop) {
      openStore(mobileAppUrls);
    } else if (checkifHaveContentAccess(authenticated, selectedContent)) {
      if (!hasRedirect) {
        redirect.countGoBack = 0;
        router.push(convertPath(PATHS.player, contentId));
      }
    } else {
      redirect.countGoBack = 0;
      const { origin } = window.location;
      const returnURL = origin + convertPath(PATHS.content, contentID);

      router.push(PATHS.login, { returnURL });
    }
  };

  const onClickContentSelected = (ev, contentid) => {
    ev.preventDefault();

    if (!contentid) {
      return false;
    }

    redirect.countGoBack = 0;
    setShowFooter(false);

    router.push(convertPath(PATHS.content, contentid));
  };

  const handleSeasonChange = async (season) => {
    setSelectedSeason(season);
    setCurrentPage(1); // Resetear la página al cambiar de temporada

    // Disparar la acción para cargar los episodios de la nueva temporada
    handleLoadMoreEpisodes(season, true);
  };

  const handleLoadMoreEpisodes = (
    season = selectedSeason,
    chageSeason = false,
  ) => {
    const nextPage = chageSeason ? 1 : currentPage + 1;

    dispatch({
      type: types.WIDGET_FETCH_SUCCESS,
      content: selectedContent,
      page: nextPage,
      season,
    });

    setCurrentPage(nextPage);
  };

  const goBackFunction = React.useCallback(async () => {
    if (
      (redirect.countGoBack >= 2 && redirect.boolGoBack) ||
      router.location.state?.oneBack.path === PATHS.player ||
      !router.location.state?.oneBack.path
    ) {
      redirect.countGoBack = 0;
      return router.push(redirect.url);
    }
    redirect.countGoBack = redirect.countGoBack + 1;
    return router.goBack();
  }, [router]);

  const catchError = (error) => {
    return router.push(PATH_ERROR, { error });
  };

  React.useEffect(() => {
    // REDIRECT TO FICHA FUTURA
    if (
      selectedContent?.live &&
      selectedContent?.contentType === CONTENT_TYPES.SPORT
    ) {
      router.push(convertPath(PATHS.live, selectedContent?.id));
    }

    if (
      ERROR_CODE[selectError.code] ||
      (!!selectError.code && !!selectError.details && !!selectError.message)
    ) {
      toastAdd(selectError);
      return goBackFunction();
    }
  }, [selectError]);

  React.useEffect(() => {
    if (
      (currentContentPosition?.currentContent?.serie?.id === contentID ||
        currentContentPosition?.currentContent?.id === contentID) &&
      !currentContentPosition?.currentContent?.entitlementTypes[0]
    ) {
      toastAdd('NOT_ENTITLEMENTS');
    }
  }, [currentContentPosition?.currentContent]);

  const onClickMyListButton = (contentId, favoritesAdded) => {
    favoritesAdded
      ? dispatch(deleteFavoriteContentByProfile(contentId))
      : dispatch(addFavoriteContentByProfile(contentId));
  };

  const contentNoImageProps = (imageMode) => {
    const { w, h } = findImageSettingByType(appSettings.imageSizes, imageMode);
    return {
      width: w || NO_IMAGE_DEFAULT(imageMode).width,
      height: h || NO_IMAGE_DEFAULT(imageMode).height,
      backgroundColorOne: NO_IMAGE_DEFAULT().backgroundColorOne,
      backgroundColorTwo: NO_IMAGE_DEFAULT().backgroundColorTwo,
    };
  };

  const handleSearchContentByFilter = React.useCallback(
    async (searchText, filterName) => {
      router.push(`${PATHS.search}?${filterName}=${searchText}`);
    },
    [router],
  );

  const inFavoritesAdded = validateFavoriteContent(favorites, contentID);
  const truncatedDescription = truncateString(
    selectedContent?.description,
    TRUCATE_STRING.METATAGS_DESCRIPTION,
  );
  const imageUrl = getContentImageByMode(selectedContent?.images, 'THUMB');

  const ratingsList = {};
  Object.entries(RAITINGS_DEFAULT).forEach(([key, value]) => {
    ratingsList[key] = t(value);
  });

  const mustShowRatingImage = () => {
    return SHOW_RATING_IMAGE || isTokenFromBR(accessToken?.access_token);
  };

  const shouldShowBuySubscription = useMemo(() => {
    if (!authenticated || selectedContent?.free || !hasPayService) {
      return true;
    }
    return validateAccessToContentByUrns(selectedContent?.urn, selectUserUrns);
  }, [authenticated, selectedContent, selectUserUrns]);

  const shouldDisableButton =
    authenticated &&
    !currentContentPosition?.currentContent?.entitlementTypes[0];

  const renderFooter = () => {
    if (!showFooter) {
      return <></>;
    }
    return useExtendedFooter ? <ExtendedFooter /> : <Footer />;
  };

  return (
    <div ref={mainDiv} className="main-content">
      {selectedContent && (
        <Seo
          title={getSeoTitleInSection(
            selectedContent,
            t,
            i18n.language,
            'Repetición',
            BRAND_INFO,
          )}
          contentTitle={getSeoTitleInSection(
            selectedContent,
            t,
            i18n.language,
            'Repetición',
            BRAND_INFO,
          )}
          description={truncatedDescription}
          image={imageUrl?.url}
        />
      )}
      <div
        // eslint-disable-next-line react/no-unknown-property
        onLoad={() => setShowFooter(true)}
        className="ContentOverviewSection"
      >
        {
          <ContentOverview
            translationTexts={translationTexts}
            content={selectedContent}
            onClickPlayerButton={onClickPlayerButton}
            onClickBackButton={goBackFunction}
            catchError={catchError}
            isLoading={!selectedContent}
            contentNoImageProps={contentNoImageProps(IMAGE_MODE.POSTER)}
            currentContentPosition={currentContentPosition}
            disabledPlayButton={shouldDisableButton}
            inFavoritesAdded={inFavoritesAdded}
            favoritesIsFetching={favoritesIsFetching}
            handleSearchContentByFilter={handleSearchContentByFilter}
            onClickMyListButton={authenticated && onClickMyListButton}
            ratingImages={selectRatingImages}
            mustShowRatingImage={mustShowRatingImage}
            redirectContent={validateUrlRedirect(currentContentPosition)}
            showPlayIcon={false}
            imageMode={IMAGE_MODE.POSTER}
            showRating={showContentDescription}
            showChannel={showContentDescription}
            showYear={showContentDescription}
            showDuration={showContentDescription}
            hasAccessTo={shouldShowBuySubscription}
          >
            <GenericModal
              title={translationTexts.share_on}
              // eslint-disable-next-line react/no-children-prop
              children={<SocialSharingList />}
              isModal={false}
              placement="right"
            />
          </ContentOverview>
        }
      </div>
      {selectedContent && selectedContent.statsUrl && (
        <IFrameComponent source={`${selectedContent.statsUrl}`} />
      )}
      <div>
        {selectedContent?.contentType === CONTENT_TYPES.MOVIE && (
          <ContentRelated
            content={selectedContentRelated}
            translationTexts={translationTexts}
            contentNoImageProps={contentNoImageProps(IMAGE_MODE.POSTER)}
            onClickContentSelected={onClickContentSelected}
            isLoading={!selectedContentRelated}
            imageMode={IMAGE_MODE.POSTER}
            catchError={() => {}}
          />
        )}
        {selectedContent?.contentType === CONTENT_TYPES.SERIE && (
          <ContentEpisodes
            translationTexts={translationTexts}
            content={
              authenticated ? contentEpisodesState : selectContentEpisodes
            }
            contentNoImageProps={contentNoImageProps(IMAGE_MODE.THUMB)}
            onClickContentSelected={onClickEpisode}
            isLoading={
              authenticated ? !contentEpisodesState : !selectContentEpisodes
            }
            catchError={catchError}
            countItem={5}
            currentSeason={currentSeason}
            disabledEpisodes={
              authenticated &&
              !currentContentPosition?.currentContent?.entitlementTypes[0]
            }
            contentSeasonsData={contentSeasonsData}
            totalEpisodes={selectTotalEpisodes}
            onLoadMore={handleLoadMoreEpisodes}
            onSeasonChange={handleSeasonChange}
          />
        )}
      </div>
      {renderFooter()}
    </div>
  );
}

export default SectionErrorBoundary(ContentOverViewSection);
