import React from 'react';
import { useDispatch } from 'react-redux';
import { changeNavBarBool } from '../../Navbar/actions';
import { clearIsNextEpisodePlayer } from '../PlayerSection/actions';

import * as actions from './actions';
import useClientConfigs from '../../../customHooks/useClientConfigs';

/**
 * DidMount like hook effect
 *
 * @export
 */
export function useDidMount(accessToken, section) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    // automatic scroll up
    window.scrollTo(0, 0);
    dispatch(changeNavBarBool(true));
    dispatch(actions.loadWidgets(accessToken, section.id, { active: true }));
    dispatch(clearIsNextEpisodePlayer());
  }, []);
}

export const usePrivacyModal = (pathCountry) => {
  const [showModal, setShowModal] = React.useState(false);
  const { enableUsPrivacy } = useClientConfigs();

  React.useEffect(() => {
    const usPrivacy = localStorage.getItem('usPrivacy');

    if (enableUsPrivacy && !usPrivacy) {
      if(pathCountry === '/us'){
        // US
        setShowModal(true);
      }else {
        // LATAM
        localStorage.setItem('usPrivacy', '1---')
      }
    }
  }, [enableUsPrivacy, pathCountry]);

  return { showModal, setShowModal };
};
