export function extractIframeHeight(iframeSource) {
    const heightMatch = iframeSource.match(/height="([^"]+)"/);
    
    if (heightMatch) {
        const heightValue = heightMatch[1];
        if (!isNaN(heightValue)) {
            return parseInt(heightValue, 10);
        }
        return heightValue;
    }
    
    const styleMatch = iframeSource.match(/style="[^"]*height:\s*([^;"]+)/);
    if (styleMatch) {
        const heightValue = styleMatch[1];
        const numericHeight = heightValue.replace('px', '');
        if (!isNaN(numericHeight)) {
            return parseInt(numericHeight, 10);
        }
        return heightValue;
    }

    return 'auto';
}