import loadable from '@loadable/component';
import { ConnectedRouter } from 'connected-react-router';
import PropTypes from 'prop-types';
import React from 'react';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { findPath, isPathRoute } from './utils';
import Cookies from 'js-cookie';

// #region //// TBX-WIDGETS /////
import { cpassAuthService, Imgcomponent } from '@tbx/experience-widgets-lib';
// #endregion

// #region /////// CONTAINERS ///////
import { HandleError, RedirectHandleError } from '../../components/HandleError';
import Navbar from '../Navbar';
import NotFound from '../NotFound';
import ContentOverViewLiveSection from '../sections/ContentOverViewLiveSection';
import ContentOverViewSection from '../sections/ContentOverViewSection';
import HomeSection from '../sections/HomeSection';
import PlayerSection from '../sections/PlayerSection';
// #endregion

import LandingLoader from '../../components/LandingLoader';
import { CONTENT_PROVIDER_AUTH } from '../../constants/contentProviderAuth';
import useStoreTime from '../../customHooks/useStoreTime';
import { getIdpClient } from '../../utils/cloudpassUtilis';
import PlayerCallbackContextProvider from '../App/playerCallbackContextProvider';
import ActivateCodeSection from '../sections/ActivateCodeSection';
import { PATHS_CONST, PATH_ERROR } from './constants';
import * as hooks from './hooks';
import * as selectors from './selectors';
import './styles.scss';
import { isExternalURl } from '../../utils/netUtils';
import useClientConfigs from '../../customHooks/useClientConfigs';
import useBrandInfo from '../../customHooks/useBrandInfo';

const LoadableSearchSection = loadable(
  () => import('../sections/SearchSection'),
);
const LoadableSection = loadable(() => import('../sections/Section'));

const hasHomeSection = (sectionCollection) =>
  !!sectionCollection.find((item) => item.isHome && item.isHome === true);

const getSectionMessage = (error, sectionCollection, t) => {
  const { code, message } = error;

  if (sectionCollection.length === 0) {
    return {
      error: t(
        'There are no sections available. Check with the system administrator.',
      ),
    };
  }

  if (!hasHomeSection(sectionCollection)) {
    // debugger;
    return {
      error: t(
        'There is no home section available. Check with the system administrator.',
      ),
    };
  }

  if (code && message) {
    return { ...error };
  }
};

/**
 * Redirige a la URL de login proporcionada por CloudPass
 *
 * @param {*} props
 * @returns
 */
function GoToLogin(props) {
  const unityAuthData = useSelector((state) =>
    selectors.selectUnityAuthData(state),
  );

  const fetchCountriesFromCDN = async () => {
    const url = `${window.__TBX_ENV__.TBX_EXPERIENCE_CDN_URL}/${window.__TBX_ENV__.TBX_EXP_CDN_CLIENT}/countries.json`;
    try {
      const result = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return await result.json();
    } catch (error) {
      return true;
    }
  };

  const redirectLogin = (state, countryListCDN) => {
    let loginURL = null;
    const { TBX_FORCE_LOGIN_URL } = window.__TBX_ENV__;

    const idpClient = getIdpClient(unityAuthData.client);
    console.log('CONTENT_PROVIDER_AUTH: ', CONTENT_PROVIDER_AUTH, idpClient);

    if (TBX_FORCE_LOGIN_URL) {
      const userCountry = unityAuthData.country || unityAuthData.ipUserCountry;
      loginURL = `${TBX_FORCE_LOGIN_URL}?failureRedirect=${encodeURIComponent(
        state.returnURL,
      )}&response_type=code&redirect_uri=${encodeURIComponent(
        state.returnURL,
      )}&client_id=${idpClient}&country=${userCountry}`;
    } else {
      const userCountry =
        window.__TBX_ENV__.TBX_FORCE_COUNTRY || unityAuthData.country;

      const getCountryFromCDN = (userCountry) => {
        if (!countryListCDN || Object.keys(countryListCDN).length === 0) {
          return userCountry;
        }
        if (countryListCDN[userCountry]) {
          return countryListCDN[userCountry];
        }
        return countryListCDN.default;
      };

      loginURL = cpassAuthService.getLoginURL(
        idpClient,
        CONTENT_PROVIDER_AUTH.IDP,
        getCountryFromCDN(userCountry),
        state.returnURL,
        null,
      );
    }

    window.location.replace(loginURL);
    return null;
  };

  React.useEffect(() => {
    const { state } = props.location;

    fetchCountriesFromCDN()
      .then((response) => {
        redirectLogin(state, response);
      })
      .catch(() => {
        redirectLogin(state, null);
      });
  }, []);
}

/**
 * Procesa el logout en CloudPass y redirige la inicio
 *
 * @param {*} props
 * @returns
 */
function GoUserLogout(props) {
  const unityAuthData = useSelector((state) =>
    selectors.selectUnityAuthData(state),
  );

  const fechtSsoLogout = async (refreshToken) => {
    const url = `${window.__TBX_ENV__.TBX_RCN_BFF}/auth/logout`;

    const bodyData = {
      refresh_token: refreshToken,
    };

    try {
      const result = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: window.__TBX_ENV__.TBX_RCN_BFF_API_KEY,
        },
        body: JSON.stringify(bodyData),
      });
      console.log(result);
      return true;
    } catch (error) {
      console.log(error);
      return true;
    }
  };

  const redirectLogout = (state) => {
    const idpClient = getIdpClient(unityAuthData.client);
    const logoutURL = cpassAuthService.getLogoutURL(
      idpClient,
      state.returnURL + '?logout=true',
    );
    window.location.replace(logoutURL);
    return null;
  };

  React.useEffect(() => {
    const { state } = props.location;
    const { useSSO } = useClientConfigs();

    if (
      useSSO &&
      Cookies.get('sso_refresh_token') &&
      window.__TBX_ENV__ &&
      window.__TBX_ENV__.TBX_RCN_BFF &&
      window.__TBX_ENV__.TBX_RCN_BFF_API_KEY
    ) {
      fechtSsoLogout(Cookies.get('sso_refresh_token'))
        .then((res) => {
          console.log('LOGOUT - THEN - fechtSsoLogout', res);
          redirectLogout(state);
        })
        .catch((res) => {
          console.log('LOGOUT - CATCH - fechtSsoLogout', res);
          redirectLogout(state);
        });
    } else {
      redirectLogout(state);
    }
  }, []);
}

function PrivateRoute({ children, ...rest }) {
  const authenticated = useSelector((state) =>
    selectors.selectAuthenticated(state),
  );
  const PATHS = useSelector((state) => selectors.selectPathList(state));
  const pathCountry = useSelector((state) =>
    selectors.selectPathCountry(state),
  );

  return (
    <Route
      {...rest}
      render={(props) => {
        const { origin } = window.location;
        const returnURL = origin + PATHS.active;

        return authenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: `${pathCountry}${PATHS_CONST.login}`,
              state: { returnURL },
            }}
          />
        );
      }}
    />
  );
}

/**
 * Renderiza las secciones y las transiciones entre las mismas.
 *
 * @param {*} { sectionCollection }
 * @returns
 */
function AppRoutes({ sectionCollection }) {
  const location = useLocation();
  const homeSectionData = sectionCollection.filter(
    (item) => item.isHome && item.isHome === true,
  )[0];
  const authenticated = useSelector((state) =>
    selectors.selectAuthenticated(state),
  );

  // #region ////// SELECTORS ///////
  const accessToken = useSelector((state) =>
    selectors.selectAccessToken(state),
  );
  const PATHS = useSelector((state) => selectors.selectPathList(state));
  const pathCountry = useSelector((state) =>
    selectors.selectPathCountry(state),
  );
  // #endregion

  const storeTime = useStoreTime();
  const [redirectPathStateId, redirectPathStateUrl] = findPath(
    location.pathname,
    PATHS,
    pathCountry,
  );
  console.warn('PATH INFO', redirectPathStateId, redirectPathStateId);
  return (
    <TransitionGroup className={'tbxAppRoutes'}>
      {/*
      This is no different than other usage of
      <CSSTransition>, just make sure to pass
      `location` to `Switch` so it can match
      the old location as it animates out.
    */}
      <CSSTransition key={location.key} classNames="fade" timeout={300}>
        <Switch location={location}>
          <Route exact path={PATHS.search} component={LoadableSearchSection} />
          <Route exact path={PATHS.home}>
            <HomeSection accessToken={accessToken} section={homeSectionData} />
          </Route>
          <Route exact path={PATHS.player}>
            <PlayerSection accessToken={accessToken} />
          </Route>
          <Route exact path={PATHS.content}>
            <ContentOverViewSection
              accessToken={accessToken}
              sectionCollection={sectionCollection.filter(
                (item) => item.inMenu && item.inMenu === true,
              )}
              storeTime={storeTime}
            />
          </Route>
          <Route exact path={PATHS.live}>
            <ContentOverViewLiveSection
              accessToken={accessToken}
              sectionCollection={sectionCollection.filter(
                (item) => item.inMenu && item.inMenu === true,
              )}
              storeTime={storeTime}
            />
          </Route>
          <Route path={PATHS.logout} component={GoUserLogout} />
          <Route path={PATHS.login} component={GoToLogin} />

          <PrivateRoute path={PATHS.active}>
            <ActivateCodeSection />
          </PrivateRoute>
          <Route path={PATH_ERROR} component={RedirectHandleError} />
          <Route
            path="/:slug"
            // eslint-disable-next-line react/no-children-prop
            children={(props) => {
              const pathname = location.pathname;

              if (
                !isPathRoute(location.pathname, PATHS) &&
                redirectPathStateUrl
              )
                return <Redirect push={false} to={redirectPathStateUrl} />;

              const section = sectionCollection.find(
                (i) => pathCountry + i.url === pathname,
              );
              if (!section) {
                console.error(`>>> section to match:"${pathname}" not found!`);
                return <NotFound />;
              }

              const externalURL = section?.redirect?.externalURL;

              if (externalURL && isExternalURl(externalURL)) {
                return window.location.replace(externalURL);
              }

              if (!section.isPublic && !authenticated) {
                return (
                  <Redirect
                    to={{
                      pathname: PATHS.login,
                      state: {
                        returnURL: window.location.origin + section.url,
                      },
                    }}
                  ></Redirect>
                );
              }

              return (
                <LoadableSection
                  {...props}
                  accessToken={accessToken}
                  section={section}
                />
              );
            }}
          />
          <Route
            // eslint-disable-next-line react/no-children-prop
            children={() => {
              const pathname = location.pathname;
              if (pathname === '' || pathname === '/')
                return <Redirect push={true} to={PATHS.home} />;
              return <NotFound />;
            }}
          />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
}

/**
 * Navbar and setup routes
 *
 * @param {*} props
 * @returns
 */
function AppSectionRouter(props) {
  const { t } = useTranslation();
  const BRAND_INFO = useBrandInfo();

  // #region ////// SELECTORS ///////
  const accessToken = useSelector((state) =>
    selectors.selectAccessToken(state),
  );
  const error = useSelector((state) => selectors.selectError(state));
  const isFetching = useSelector((state) => selectors.selectIsFetching(state));
  const pathCountry = useSelector((state) =>
    selectors.selectPathCountry(state),
  );
  const sectionCollection = useSelector((state) =>
    selectors.selectSectionCollection(state),
  );
  // #endregion

  // #region /////// HOOK EFFECTS ///////
  hooks.useSectionsFetch(accessToken);
  hooks.usePathsHok(pathCountry); // importante para que las rutas funcionen
  // #endregion

  const errorResult = getSectionMessage(error, sectionCollection, t);

  const sectionsMenu = sectionCollection.filter(
    (item) => item.inMenu && item.inMenu === true,
  );
  const subSections = sectionCollection.filter(
    (item) =>
      item.parent && typeof item.inMenu === 'boolean' && item.inMenu === false,
  );
  return (
    <ConnectedRouter history={props.history}>
      <BrowserRouter>
        <PlayerCallbackContextProvider>
          {isDesktop ? (
            <Navbar sections={sectionsMenu} subSections={subSections} />
          ) : (
            <Imgcomponent
              className="logoContainer logo_mobile"
              src={'/images/logo.svg'}
              alt={BRAND_INFO.CLIENT_NAME}
              loading={'eager'}
            />
          )}
          {isFetching && (
            <main className="app-section-load">
              <LandingLoader className="app-section-load__loadingSpinner" />
            </main>
          )}
          {!isFetching &&
            (errorResult ? (
              <HandleError error={errorResult} navBar={false} />
            ) : (
              <AppRoutes sectionCollection={sectionCollection} />
            ))}
        </PlayerCallbackContextProvider>
      </BrowserRouter>
    </ConnectedRouter>
  );
}

AppSectionRouter.propTypes = {
  history: PropTypes.object.isRequired,
};

export default AppSectionRouter;
