import { GA_SECTION_NAMES } from '../constants/googleAnalyticEventTypes';
import { getSubPathFromUrl } from './pathRoutesUtils';

export const getSectionInfoSeo = (
  currentSection,
  sections,
  pathname,
  defaultValue,
) => {
  if (currentSection) {
    return (
      (currentSection && currentSection.seo && currentSection.seo.title) ||
      GA_SECTION_NAMES[defaultValue]
    );
  }

  const clearPath = getSubPathFromUrl(pathname);
  const valueSeo = sections.length && sections.find((s) => s.url === clearPath);

  return (
    (valueSeo && valueSeo.seo && valueSeo.seo.title) ||
    GA_SECTION_NAMES[defaultValue]
  );
};
