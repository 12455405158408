// Import the functions you need from the SDKs you need

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from 'firebase/messaging';
import { sendFirebaseToken } from '../containers/App/actions';
import { subscribeToDefaultTopic } from '../containers/ProfileManager/actions';
import 'core-js/stable';
import 'cross-fetch/polyfill';
import { canInitServiceWorker } from '../utils/firebaseUtils';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: window.__TBX_ENV__.TBX_FIREBASE_API_KEY,
  appId: window.__TBX_ENV__.TBX_FIREBASE_APP_ID,
  authDomain: window.__TBX_ENV__.TBX_FIREBASE_AUTH_DOMAIN,
  databaseURL: window.__TBX_ENV__.TBX_FIREBASE_DATABASE_URL,
  measurementId: window.__TBX_ENV__.TBX_FIREBASE_MEASUREMENT_ID,
  messagingSenderId: window.__TBX_ENV__.TBX_FIREBASE_MESSAGING_SENDER_ID,
  projectId: window.__TBX_ENV__.TBX_FIREBASE_PROJECT_ID,
  storageBucket: window.__TBX_ENV__.TBX_FIREBASE_STORAGE_BUCKET,
};

const app = initializeApp(firebaseConfig);
// getAnalytics(app);
export const analytics = getAnalytics(app);

export const requestForToken = (dispatch) => {

  if(!canInitServiceWorker()){
    return
  }

  window.navigator.serviceWorker &&
    window.navigator.serviceWorker
      .register('../firebase-messaging-sw.js')
      .then(({ active }) =>
        console.log('ServiceWorkerRegistration', active?.state),
      );

  isSupported().then((result) => {
    if (result) {
      return getToken(getMessaging(), {
        vapidKey: window.__TBX_ENV__.TBX_FIREBASE_VAPID_KEY,
      })
        .then((currentToken) => {
          if (currentToken) {
            dispatch(sendFirebaseToken(currentToken));
            dispatch(subscribeToDefaultTopic(currentToken));
            // dispatch(updateNotificationTopicsFirebaseToken(currentToken));
            console.log('firebase token', currentToken);
          } else {
            console.log('no current token:');
          }
        })
        .catch((err) => {
          console.log('error', err);
        });
    } else {
      console.log('Browser not supported - SDK Firebase');
    }
  });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.let messageResolve; // To store the resolve function of the promise
export const onMessageListener = (callback) => {
  onMessage(getMessaging(), (payload) => {
    callback(payload);
  });
};
