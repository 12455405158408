import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import useBrandInfo from '../../customHooks/useBrandInfo';
import useClientConfigs from '../../customHooks/useClientConfigs';

const currentYear = new Date().getFullYear();

function Footer() {
  const { t } = useTranslation();
  const BRAND_INFO = useBrandInfo();
  const {
    showSocialNetworksInFooter,
    hasPrivacyPolicyLink,
    hasCustomHelpSlug,
    socialLinks,
    hasFrequentQuestions,
    hasConsumerProtection,
    hasCookiesPolicy,
  } = useClientConfigs();

  return (
    <footer className="footer dtv-footer-container-home ">
      <div className="footer-container">
        <div className="footer-linksLegales">
          <div className="footer-linksLegales-links">
            <a
              className="footer-linksLegales-links-Terminos"
              href="/terminos-de-uso"
            >
              {t('Terms of service')}
            </a>
            {!!hasPrivacyPolicyLink && (
              <>
                <span className="footer-linksLegales-links-span">|</span>
                <a
                  className="footer-linksLegales-links-Politica"
                  href="/privacidad"
                >
                  {t('Privacy policy')}
                </a>
              </>
            )}
            <span className="footer-linksLegales-links-span">|</span>
            <a
              className="footer-linksLegales-links-Help"
              href={hasCustomHelpSlug ? '/ayuda-web' : '/ayuda'}
            >
              {t('Help')}
            </a>
            {!!hasFrequentQuestions && (
              <>
                <span className="footer-linksLegales-links-span">|</span>
                <a
                  className="footer-linksLegales-links-Politica"
                  href="/preguntas-frecuentes"
                >
                  {t('Frequent questions')}
                </a>
              </>
            )}
            {!!hasConsumerProtection && (
              <>
                <span className="footer-linksLegales-links-span">|</span>
                <a
                  className="footer-linksLegales-links-Politica"
                  href="/proteccion-al-consumidor-sic"
                >
                  {t('Consumer protection')}
                </a>
              </>
            )}
            {!!hasCookiesPolicy && (
              <>
                <span className="footer-linksLegales-links-span">|</span>
                <a
                  className="footer-linksLegales-links-Politica"
                  href="/politica-de-cookies"
                >
                  {t('Cookies policy')}
                </a>
              </>
            )}
          </div>
        </div>
        {showSocialNetworksInFooter && (
          <div className="container-social-links-icons">
            {socialLinks.map((link, index) => (
              <a
                key={index}
                href={link.href}
                target="_blank"
                rel="noreferrer"
                title={link.title}
                className="custom-icon-container"
              >
                <i className={`bi ${link.icon} custom-icon`}></i>
              </a>
            ))}
          </div>
        )}
        <div className="footer-rightsReserved">{`© ${currentYear} ${
          BRAND_INFO.CLIENT_NAME
        }. ${t('All rights reserved.')}`}</div>
      </div>
    </footer>
  );
}

export default Footer;
