import React, { useRef, useLayoutEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { handleClick, handleMouseEnter } from './utils';
import './styles.scss';

function HtmlComponent({ code, csscode }) {
  const history = useHistory();
  const [currentScroll, setScroll] = React.useState(0);
  const cssCode = `
    <style>
      ${csscode}
    </style>
  `;
	
  const elRef = useRef();

  useLayoutEffect(() => {
    const range = document.createRange()
    range.selectNode(elRef.current)
    const documentFragment = range.createContextualFragment(code)

    elRef.current.innerHTML = ''
    elRef.current.append(documentFragment)
  },[])

  return (
    <section
      className="html-component"
      ref={elRef}
      dangerouslySetInnerHTML={{
        __html: `
        ${csscode ? cssCode : ''}
        ${code}
      `,
      }}
      onMouseEnter={() =>
        handleMouseEnter({
          currentScroll,
          setScroll,
        })
      }
      onClick={(event) =>
        handleClick({
          event,
          history,
          currentScroll,
          setScroll,
        })
      }
    />
  );
}

HtmlComponent.propTypes = {
  code: PropTypes.string.isRequired,
  csscode: PropTypes.string,
  jscode: PropTypes.string,
};

export default memo(HtmlComponent);
