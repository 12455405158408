import { CONTENT_TYPES } from '../constants/contentTypes';
const ENV = window.__TBX_ENV__;

function getEnvConfig(currentENV) {
  if (
    !currentENV.TBX_CLIENT_KEY ||
    !currentENV.TBX_CLOUDPASS_API ||
    !currentENV.TBX_UNITY_API
  ) {
    console.error(
      '>>>>>> Invalid __TBX_ENV__ definition! check env variables build process',
    );
    return null;
  }

  const IDP_CLIENT = currentENV.TBX_IDP_CLIENT || null;
  const IDP_SHORTCODE = currentENV.TBX_IDP_SHORTCODE || null;
  const useCloudhubConfig = !IDP_CLIENT || !IDP_SHORTCODE;

  return {
    client: {
      SUB: currentENV.TBX_CLIENT_KEY,
      CONFIG_URI: currentENV.TBX_EXPERIENCE_CONFIG,
    },
    cloudpass: {
      API_URL: currentENV.TBX_CLOUDPASS_API,
      IDP_CLIENT,
      IDP_SHORTCODE,
      USE_CLOUDHUB_CONFIG: currentENV.TBX_IDP_USE_CLOUDHUB || useCloudhubConfig,
    },
    unity: {
      API_URL: currentENV.TBX_UNITY_API,
    },
    settings: {
      hispanomedios: {
        useDirectAccessToAddOns: true,
        hasDirectAccessToAddOns: true,
        hasPrivacyPolicyLink: true,
        hasPayService: true,
        showContentDescription: false,
        hasCustomHelpSlug: false,
        useSSO: false,
        showSocialNetworksInFooter: false,
        hasMobileLanding: false,
        enabledResponsiveWeb: false,
        searchFilters: {
          contentType: `${CONTENT_TYPES.SPORT},${CONTENT_TYPES.BROADCAST},${CONTENT_TYPES.MOVIE},${CONTENT_TYPES.SERIE}`,
        },
        enableUsPrivacy: true,
        enableCustomTags: false,
      },
      tbxgo: {
        useDirectAccessToAddOns: false,
        hasDirectAccessToAddOns: false,
        hasPrivacyPolicyLink: true,
        hasPayService: true,
        showContentDescription: false,
        hasCustomHelpSlug: false,
        useSSO: false,
        showSocialNetworksInFooter: false,
        hasMobileLanding: false,
        enabledResponsiveWeb: false,
        searchFilters: {
          contentType: `${CONTENT_TYPES.SPORT},${CONTENT_TYPES.BROADCAST},${CONTENT_TYPES.MOVIE},${CONTENT_TYPES.SERIE}`,
        },
        enableUsPrivacy: false,
        enableCustomTags: false,
      },
      mio: {
        useDirectAccessToAddOns: false,
        hasDirectAccessToAddOns: false,
        hasPrivacyPolicyLink: false,
        hasPayService: false,
        showContentDescription: true,
        hasCustomHelpSlug: true,
        useSSO: false,
        showSocialNetworksInFooter: false,
        hasMobileLanding: false,
        enabledResponsiveWeb: false,
        searchFilters: {
          contentType: `${CONTENT_TYPES.MOVIE},${CONTENT_TYPES.SERIE}`,
        },
        enableUsPrivacy: false,
        enableCustomTags: false,
      },
      sportshub: {
        useDirectAccessToAddOns: false,
        hasDirectAccessToAddOns: false,
        hasPrivacyPolicyLink: true,
        hasPayService: true,
        showContentDescription: false,
        hasCustomHelpSlug: false,
        useSSO: false,
        showSocialNetworksInFooter: false,
        hasMobileLanding: false,
        enabledResponsiveWeb: false,
        searchFilters: {
          contentType: `${CONTENT_TYPES.SPORT},${CONTENT_TYPES.BROADCAST},${CONTENT_TYPES.MOVIE},${CONTENT_TYPES.SERIE}`,
        },
        enableUsPrivacy: false,
        enableCustomTags: false,
      },
      winsports: {
        useDirectAccessToAddOns: false,
        hasDirectAccessToAddOns: false,
        hasPrivacyPolicyLink: false,
        hasPayService: true,
        showContentDescription: false,
        hasCustomHelpSlug: false,
        useSSO: false,
        showSocialNetworksInFooter: true,
        socialLinks: [
          {
            href: 'https://www.facebook.com/WinSportsTv',
            title: 'facebook',
            icon: 'bi-facebook',
          },
          {
            href: 'https://x.com/WinSportsTV',
            title: 'twitter-x',
            icon: 'bi-twitter-x',
          },
          {
            href: 'https://www.instagram.com/winsportstv/',
            title: 'instagram',
            icon: 'bi-instagram',
          },
          {
            href: 'https://www.youtube.com/@winsportstv',
            title: 'youtube',
            icon: 'bi-youtube',
          },
        ],
        hasMobileLanding: false,
        enabledResponsiveWeb: false,
        searchFilters: {
          contentType: `${CONTENT_TYPES.SPORT},${CONTENT_TYPES.BROADCAST},${CONTENT_TYPES.MOVIE},${CONTENT_TYPES.SERIE}`,
        },
        enableUsPrivacy: false,
        hasFrequentQuestions: true,
        hasConsumerProtection: true,
        hasCookiesPolicy: true,
        enableCustomTags: true
      },
      motorplay: {
        useDirectAccessToAddOns: false,
        hasDirectAccessToAddOns: false,
        hasPrivacyPolicyLink: true,
        hasPayService: true,
        showContentDescription: false,
        hasCustomHelpSlug: false,
        useSSO: false,
        showSocialNetworksInFooter: true,
        hasMobileLanding: false,
        enabledResponsiveWeb: false,
        socialLinks: [
          {
            href: 'https://www.facebook.com/motorplaytv',
            title: 'facebook',
            icon: 'bi-facebook',
          },
          {
            href: 'https://x.com/motorplaytv',
            title: 'twitter-x',
            icon: 'bi-twitter-x',
          },
          {
            href: 'https://www.instagram.com/motorplaytv',
            title: 'instagram',
            icon: 'bi-instagram',
          },
          {
            href: 'https://api.whatsapp.com/send/?phone=5491176138604&text&type=phone_number&app_absent=0',
            title: 'whatsapp',
            icon: 'bi-whatsapp',
          },
          {
            href: 'https://youtube.com/@motorplaytv',
            title: 'youtube',
            icon: 'bi-youtube',
          },
          {
            href: 'https://www.tiktok.com/@motorplaytv',
            title: 'tiktok',
            icon: 'bi-tiktok',
          },
        ],
        searchFilters: {
          contentType: `${CONTENT_TYPES.SPORT},${CONTENT_TYPES.BROADCAST},${CONTENT_TYPES.MOVIE},${CONTENT_TYPES.SERIE}`,
        },
        enableUsPrivacy: false,
        enableCustomTags: false,
      },
      rcn: {
        useDirectAccessToAddOns: false,
        hasDirectAccessToAddOns: false,
        hasPrivacyPolicyLink: true,
        hasPayService: false,
        showContentDescription: false,
        hasCustomHelpSlug: false,
        useExtendedFooter: true,
        useSSO: true,
        showSocialNetworksInFooter: false,
        hasMobileLanding: false,
        enabledResponsiveWeb: true,
        searchFilters: {
          contentType: `${CONTENT_TYPES.SPORT},${CONTENT_TYPES.BROADCAST},${CONTENT_TYPES.MOVIE},${CONTENT_TYPES.SERIE}`,
        },
        newsletterLink: 'https://www.canalrcn.com/boletin/',
        socialLinks: [
          {
            href: 'https://www.facebook.com/CanalRCN/',
            title: 'facebook',
            icon: 'bi-facebook',
          },
          {
            href: 'https://x.com/CanalRCN',
            title: 'twitter-x',
            icon: 'bi-twitter-x',
          },
          {
            href: 'https://www.instagram.com/canalrcn/',
            title: 'instagram',
            icon: 'bi-instagram',
          },
          {
            href: 'https://www.tiktok.com/@canalrcn?lang=es',
            title: 'tiktok',
            icon: 'bi-tiktok',
          },
          {
            href: 'https://www.whatsapp.com/channel/0029Va4Xq8N9xVJbLTXCa81D',
            title: 'whatsapp',
            icon: 'bi-whatsapp',
          },
          {
            href: 'https://www.youtube.com/channel/UCSCAJJ1RJ4rfRTVW4gjYTpQ',
            title: 'youtube',
            icon: 'bi-youtube',
          },
        ],
        corporateSection: {
          sectionTitle: 'Portal corporativo',
          links: [
            {
              title: 'Atención al televidente',
              href: 'https://corporativocanalrcn.com/atencion-al-televidente/',
            },
            {
              title: 'Responsabilidad Social',
              href: 'https://www.responsabilidadsocialcanalrcn.com/',
            },
            {
              title: 'Prensa RCN',
              href: 'https://www.prensacanalrcn.com/',
            },
            {
              title: 'Línea ética',
              href: 'https://corporativocanalrcn.com/linea-etica/',
            },
            {
              title: 'Atención al inversionista',
              href: 'https://corporativocanalrcn.com/atencion-al-inversionista/',
            },
            {
              title: 'Código de autorregulación',
              href: 'https://media.canalrcn.com/politicas/CodigoAutoregulacion.pdf',
            },
            {
              title: 'OBA',
              href: 'https://corporativocanalrcn.com/oba/',
            },
          ],
        },
        ourPortals: {
          sectionTitle: 'Nuestros Portales',
          links: [
            {
              title: 'Canal RCN',
              href: 'https://www.canalrcn.com/',
            },
            {
              title: 'Zona interactiva',
              href: 'https://www.zona-interactiva.canalrcn.com/',
            },
            {
              title: 'Noticias RCN',
              href: 'https://www.noticiasrcn.com/?_gl=1*d0lw4v*_ga*ODA5ODE5OTI3LjE3Mjc4NzQ0NDA.*_ga_L1L2HC84BZ*MTczMzMyMTM5OC43LjEuMTczMzMyMTcwNS42MC4wLjA.',
            },
            {
              title: 'SuperLike',
              href: 'https://www.superlike.com/',
            },
          ],
        },
        partnerPortals: {
          sectionTitle: 'Portales aliados',
          links: [
            {
              title: 'Estudios RCN',
              href: 'https://www.estudiosrcn.com/',
            },
            {
              title: 'RCN Comerciales',
              href: 'https://www.rcncomerciales.com/es/inicio-espanol/',
            },
            {
              title: 'RCN Radio',
              href: 'https://www.rcnradio.com/',
            },
            {
              title: 'La República',
              href: 'https://www.larepublica.co/',
            },
            {
              title: 'Win Sports',
              href: 'https://www.winsports.co/',
            },
            {
              title: 'NTN 24',
              href: 'https://www.ntn24.com/',
            },
            {
              title: 'TDT',
              href: 'https://www.tdtparatodos.tv/',
            },
          ],
        },
        enableCustomTags: false,
      },
      default: {
        useDirectAccessToAddOns: false,
        hasDirectAccessToAddOns: false,
        hasPrivacyPolicyLink: true,
        hasPayService: true,
        showContentDescription: false,
        hasCustomHelpSlug: false,
        useSSO: false,
        showSocialNetworksInFooter: false,
        hasMobileLanding: false,
        enabledResponsiveWeb: false,
        hasFrequentQuestions: false,
        hasConsumerProtection: false,
        hasCookiesPolicy: false,
        searchFilters: {
          contentType: `${CONTENT_TYPES.SPORT},${CONTENT_TYPES.BROADCAST},${CONTENT_TYPES.MOVIE},${CONTENT_TYPES.SERIE}`,
        },
        enableUsPrivacy: false,
        enableCustomTags: false,
      },
    },
  };
}

const CONFIG = getEnvConfig(ENV);

export default CONFIG;
