import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import useBrandInfo from '../../customHooks/useBrandInfo';
import useClientConfigs from '../../customHooks/useClientConfigs';
import { Imgcomponent } from '@tbx/experience-widgets-lib';

const currentYear = new Date().getFullYear();

function ExtendedFooter() {
  const { t } = useTranslation();
  const BRAND_INFO = useBrandInfo();
  const {
    hasPrivacyPolicyLink,
    hasCustomHelpSlug,
    socialLinks,
    corporateSection,
    ourPortals,
    partnerPortals,
  } = useClientConfigs();

  return (
    <footer className="footer dtv-footer-container-home ">
      <div className="footer-container-info-links">
        <div className="footer-container-info-links-social">
          <div className="container-logo">
            <Imgcomponent
              className="logoFooter"
              src={'/images/logoWhite.svg'}
              alt={BRAND_INFO.CLIENT_NAME}
              loading={'eager'}
            />
          </div>
          {/*  TODO: Request by client remove button, keep to improve logic conditional to use on others clients
          <div className="container-newsletter">
            <Button
              href={newsletterLink}
              target={'_blank'}
              rel="noreferrer"
              variant="light"
            >
              Recibe nuestros boletines
            </Button>
          </div> */}
          <div className="container-social-links">
            <h5>Síguenos por nuestras redes</h5>
            {socialLinks && (
              <div className="container-social-links-icons">
                {socialLinks.map((link, index) => (
                  <a
                    key={index}
                    href={link.href}
                    target="_blank"
                    rel="noreferrer"
                    title={link.title}
                    className="custom-icon-container"
                  >
                    <i className={`bi ${link.icon} custom-icon`}></i>
                  </a>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="footer-container-info-links-list-external-sites-one">
          <h6>{corporateSection.sectionTitle}</h6>
          <div className="footer-links-container">
            {corporateSection.links.map((link, index) => (
              <a
                key={`corporate-link-${index}`}
                className="footer-linksSites-links"
                href={link.href}
                target="_blank"
                rel="noreferrer"
              >
                {t(link.title)}
              </a>
            ))}
          </div>
        </div>
        <div className="footer-container-info-links-list-external-sites-two">
          <h6>{ourPortals.sectionTitle}</h6>
          <div className="footer-links-container">
            {ourPortals.links.map((link, index) => (
              <a
                key={`corporate-link-${index}`}
                className="footer-linksSites-links"
                href={link.href}
                target="_blank"
                rel="noreferrer"
              >
                {t(link.title)}
              </a>
            ))}
          </div>
        </div>
        <div className="footer-container-info-links-list-external-sites-three">
          <h6>{partnerPortals.sectionTitle}</h6>
          <div className="footer-links-container">
            {partnerPortals.links.map((link, index) => (
              <a
                key={`corporate-link-${index}`}
                className="footer-linksSites-links"
                href={link.href}
                target="_blank"
                rel="noreferrer"
              >
                {t(link.title)}
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="footer-container-legals">
        <div className="footer-linksLegales">
          <div className="footer-linksLegales-links">
            <a
              className="footer-linksLegales-links-Terminos"
              href="/terminos-de-uso"
              target="_blank"
            >
              {t('Terms of service')}
            </a>
            {!!hasPrivacyPolicyLink && (
              <>
                <span className="footer-linksLegales-links-span"> </span>
                <a
                  className="footer-linksLegales-links-Politica"
                  href="/privacidad"
                  target="_blank"
                >
                  {t('Privacy policy')}
                </a>
              </>
            )}
            <span className="footer-linksLegales-links-span"> </span>
            <a
              className="footer-linksLegales-links-Help"
              target="_blank"
              href={hasCustomHelpSlug ? '/ayuda-web' : '/ayuda'}
              rel="noreferrer"
            >
              {t('Help')}
            </a>
          </div>
        </div>
        <div className="footer-rightsReserved-extended">{`Un producto de ${BRAND_INFO.CLIENT_NAME} ${BRAND_INFO.CLIENT_MEDIA} ${currentYear}`}</div>
      </div>
    </footer>
  );
}

export default ExtendedFooter;
