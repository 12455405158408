import PLATFORM_INFO from '../constants/platforms';
import { getStoreUrlByPlataform } from './appSettingUtils';
import { checkifPropertiesExist } from './objectUtils';

/**
 * Verifies if an user is authenticated or if the content is free
 *
 * @param  {*}          authenticated  Prop to verify
 * @param  {*}          obj Object to verify
 * @return {Boolean}
 */
export const checkifHaveContentAccess = (authenticated, obj) => {
  return (
    authenticated || (checkifPropertiesExist('free', obj).status && obj.free)
  );
};

export const openStore = (mobileAppUrls) => {
  const hrefAppStore =
    mobileAppUrls &&
    getStoreUrlByPlataform(mobileAppUrls, PLATFORM_INFO.ios.name);
  const hrefgooglePlay =
    mobileAppUrls &&
    getStoreUrlByPlataform(mobileAppUrls, PLATFORM_INFO.android.name);
  const userAgent = navigator.userAgent;

  if (/iPhone|iPad/i.test(userAgent)) {
    window.open(hrefAppStore);
  } else if (/Android/i.test(userAgent)) {
    window.open(hrefgooglePlay);
  }
};
