/**
 * Get section navigation collection with URLs and images.
 *
 * @param {Object} options - Options for generating the section navigation collection.
 * @param {Array} options.sectionNavItems - The array of section navigation items.
 * @param {Object} options.paths - An object mapping sectionToGoId to URLs.
 * @param {Array} options.sections - The array of sections containing images.
 *
 * @return {Array} An array of section navigation items with URLs and images.
 */
export const getSectionNavCollection = ({
  sectionNavItems,
  paths,
  sections,
}) => {
  const filtered = sectionNavItems
    ?.filter((section) => section.active)
    ?.sort((a, b) => a.order - b.order);
  const sectionNavItemsPaths =
    filtered?.map((item) => {
      const url = paths[item?.sectionToGoId];

      const images =
        sections?.find((section) => section.id === item.sectionToGoId)
          ?.images || [];
      return {
        ...item,
        url: url || '',
        images,
      };
    }) || [];

  return sectionNavItemsPaths;
};
