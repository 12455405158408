import queryString from 'query-string';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import usePrevious from '../../customHooks/usePrevious';
import { onMessageListener, requestForToken } from '../../firebase';
import { getCurrentProfileData } from '@tbx/utils/ProfileUtils';
import { decodeToken } from '@tbx/utils/jwtUtils';
import * as profileActions from '../ProfileManager/actions';
import * as actions from './actions';
import * as selectors from './selectors';
import { canInitServiceWorker } from '@tbx/utils/firebaseUtils';
import { selectUnityAuthData } from './selectors';

export function _useQueryParams(location) {
  let searchString = location.search || '';

  if (searchString.startsWith('?')) {
    searchString = searchString.substr(1);
  }

  return queryString.parse(searchString);
}

/**
 * DidMount like hook effect
 *
 * @export
 */
export function useDidMount(config, currentToken) {
  const dispatch = useDispatch();
  const authenticated = useSelector((state) =>
    selectors.selectAuthenticated(state),
  );
  const unityAuthData = useSelector((state) => selectUnityAuthData(state));
  const location = useSelector((state) => selectors.selectLocation(state));
  const queryParams = _useQueryParams(location);
  const { code, logout, one_time_token, favorite_team } = queryParams;

  const addTemporalToken = () => {
    const params = new URLSearchParams(window.location.search);
    params.delete('one_time_token');
    params.delete('cp');
    window.history.replaceState(
      null,
      '',
      Array.from(params).length === 0
        ? window.location.pathname
        : `?${params}${location.hash}`,
    );
  };

  const deleteCode = () => {
    const params = new URLSearchParams(window.location.search);
    params.delete('code');
    window.history.replaceState(
      null,
      '',
      Array.from(params).length === 0
        ? window.location.pathname
        : `?${params}${location.hash}`,
    );
  };

  const deleteFavoriteTeam = () => {
    const params = new URLSearchParams(window.location.search);
    params.delete('favorite_team');
    window.history.replaceState(
      null,
      '',
      Array.from(params).length === 0
        ? window.location.pathname
        : `?${params}${location.hash}`,
    );
  };

  React.useEffect(() => {
    if (!authenticated) {
      code && dispatch(actions.authnLogin(code, window.location.origin));
      one_time_token && dispatch(actions.authnOTTLogin(one_time_token));
      deleteCode();
    } else if (authenticated && logout === 'true') {
      dispatch(actions.deviceLogout());
    } else if (
      authenticated &&
      (window.location.search.includes('code') ||
        window.location.search.includes('one_time_token'))
    ) {
      code && dispatch(actions.authnLogin(code, window.location.origin));
      one_time_token && dispatch(actions.authnOTTLogin(one_time_token));
      deleteCode();
    }

    if (favorite_team) {
      dispatch(profileActions.setFavoriteTeam(favorite_team));
      deleteFavoriteTeam();
    }

    if (one_time_token) {
      addTemporalToken();
    } else {
      dispatch(actions.appStartup(null, authenticated, unityAuthData));
    }
  }, [authenticated]);
}

export function useUserLogout() {
  const dispatch = useDispatch();
  const authenticated = useSelector((state) =>
    selectors.selectAuthenticated(state),
  );
  const authenticatedPrev = usePrevious(authenticated);

  React.useEffect(() => {
    if (!authenticated && authenticatedPrev) {
      dispatch(actions.appStartup());
    }
  }, [authenticated, authenticatedPrev, dispatch]);
}

export function useProfiles(currentToken) {
  const dispatch = useDispatch();
  const tokenData = currentToken.access_token
    ? decodeToken(currentToken.access_token)
    : {};
  const { profile } = tokenData;
  const authenticated = useSelector((state) =>
    selectors.selectAuthenticated(state),
  );
  const { parentalControl } = useSelector((state) =>
    selectors.selectAppSettings(state),
  );
  React.useEffect(() => {
    dispatch(actions.toggleProfilesModal(false));

    if (parentalControl !== undefined) {
      authenticated && dispatch(profileActions.fetchProfiles(currentToken));
    }

    authenticated &&
      profile &&
      dispatch(profileActions.fetchFavoriteContentByProfile(currentToken));

    authenticated && profile && requestForToken(dispatch);
  }, [authenticated, profile, parentalControl]);
}

export function useCurrentProfileValidation(currentToken) {
  const dispatch = useDispatch();
  const tokenData = currentToken.access_token
    ? decodeToken(currentToken.access_token)
    : {};
  const { profile } = tokenData;
  const authenticated = useSelector((state) =>
    selectors.selectAuthenticated(state),
  );
  const profileCollection = useSelector((state) =>
    selectors.selectProfileCollection(state),
  );

  React.useEffect(() => {
    authenticated &&
      profile &&
      profileCollection.length > 0 &&
      !getCurrentProfileData(profileCollection, profile) &&
      dispatch(actions.toggleProfilesModal(true));
  }, [profileCollection]);
}

/**
 * useNewRelicAttributes like hook effect
 *
 * @export
 */
export function useNewRelicAttributes(accessToken) {
  React.useEffect(() => {
    if (window.newrelic) {
      const jwtData = accessToken?.access_token
        ? decodeToken(accessToken?.access_token)
        : {};

      if (Object.keys(jwtData).length > 0) {
        const {
          aud,
          client,
          country,
          customer,
          device,
          exp,
          language,
          maxRating,
          profile,
          subscriber,
        } = jwtData;

        window.newrelic.setCustomAttribute('AUD', aud);
        window.newrelic.setCustomAttribute('Client', client);
        window.newrelic.setCustomAttribute('Customer', customer);
        window.newrelic.setCustomAttribute('Country', country);
        window.newrelic.setCustomAttribute('Device', device);
        window.newrelic.setCustomAttribute('EXP', exp);
        window.newrelic.setCustomAttribute('Language', language);
        window.newrelic.setCustomAttribute('MaxRating', maxRating);
        window.newrelic.setCustomAttribute('Profile', profile);
        window.newrelic.setCustomAttribute('Subscriber', subscriber);
      }
    } else {
      console.warn(
        'New Relic setting is not implemented for the current environment',
      );
    }
  }, [accessToken?.access_token]);
}

export function useRatingImages(currentToken) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(actions.getRatingImages(currentToken));
  }, []);
}

export function useGetAccountInformation(currentToken) {
  const dispatch = useDispatch();
  const authenticated = useSelector((state) =>
    selectors.selectAuthenticated(state),
  );

  React.useEffect(() => {
    if (authenticated) {
      dispatch(actions.loadAccountInformation(currentToken));
    }
  }, [currentToken]);
}

export function useNotifications() {
  const dispatch = useDispatch();
  const handleMessage = React.useCallback(
    (payload) => {
      console.log('Received message:', payload);
      const { link } = payload.fcmOptions;
      const { image } = payload.notification;
      dispatch(
        actions.toastAdd({
          title: payload.notification.title,
          message: payload.notification.body,
          link: link ?? '',
          image: image ?? '',
        }),
      );
    },
    [dispatch],
  );
  React.useEffect(() => {
    if (canInitServiceWorker()) {
      onMessageListener(handleMessage);
    }
  }, [handleMessage]);
}

export function useScript({ innerHTML, src, type }) {
  React.useEffect(() => {
    const scriptContent = document.createElement('script');
    scriptContent.innerHTML = `${innerHTML}`;
    document.body.appendChild(scriptContent);

    const scriptTag = document.createElement('script');
    scriptTag.type = type;
    scriptTag.src = src;
    scriptTag.async = true;
    scriptTag.defer = true;
    document.body.appendChild(scriptTag);

    // Cleanup function to remove scripts on unmount
    return () => {
      document.body.removeChild(scriptContent);
      document.body.removeChild(scriptTag);
    };
  }, [innerHTML, src, type]);
}
