import { types } from './constants';

export const fetchAvatars = (tokenData) => ({
  type: types.AVATARS_FETCH,
  tokenData,
});

export const fetchAvatarsError = (error) => ({
  type: types.AVATARS_FETCH_FAILURE,
  error,
});

export const fetchAvatarsSuccess = (avatars) => ({
  type: types.AVATARS_FETCH_SUCCESS,
  avatars,
});

export const fetchProfiles = (tokenData) => ({
  type: types.PROFILES_FETCH,
  tokenData,
});

export const fetchProfilesLoadError = (error) => ({
  type: types.PROFILES_FETCH_FAILURE,
  error,
});

export const fetchProfilesLoadSuccess = (
  profiles,
  avatars,
  currentProfile,
) => ({
  type: types.PROFILES_FETCH_SUCCESS,
  profiles,
  avatars,
  currentProfile,
});

export const setCurrentProfile = (profileID) => ({
  type: types.SET_CURRENT_PROFILE_FETCH,
  profileID,
});

export const setCurrentProfileSuccess = (profileID) => ({
  type: types.SET_CURRENT_PROFILE_FETCH_SUCCESS,
  profileID,
});

export const setCurrentProfileLoadError = (error) => ({
  type: types.SET_CURRENT_PROFILE_FAILURE,
  error,
});

export const fetchCurrentContentPositionByProfile = (tokenData, contentID) => ({
  type: types.CURRENT_CONTENT_POSITION_BY_PROFILE_FETCH,
  tokenData,
  contentID,
});

export const fetchCurrentContentPositionByProfileError = (error) => ({
  type: types.CURRENT_CONTENT_POSITION_BY_PROFILE_FETCH_FAILURE,
  error,
});

export const fetchCurrentContentPositionByProfileSuccess = (content) => ({
  type: types.CURRENT_CONTENT_POSITION_BY_PROFILE_FETCH_SUCCESS,
  content,
});

export const fetchCreateProfile = (payload, pinCodeValue) => ({
  type: types.CREATE_PROFILE_FETCH,
  payload,
  pinCodeValue,
});

export const fetchCreateProfileSuccess = () => ({
  type: types.CREATE_PROFILE_FETCH_SUCCESS,
});

export const fetchCreateProfileError = (error) => ({
  type: types.CREATE_PROFILE_FETCH_FAILURE,
  error,
});

export const fetchEditProfile = (profileID, payload, pinCodeValue) => ({
  type: types.EDIT_PROFILE_FETCH,
  profileID,
  payload,
  pinCodeValue,
});

export const fetchEditProfileSuccess = () => ({
  type: types.EDIT_PROFILE_FETCH_SUCCESS,
});

export const fetchEditProfileError = (error) => ({
  type: types.EDIT_PROFILE_FETCH_FAILURE,
  error,
});

export const fetchProfileById = (profileID) => ({
  type: types.PROFILE_BY_ID_FETCH,
  profileID,
});

export const fetchProfileByIdSuccess = (profileData) => ({
  type: types.PROFILE_BY_ID_FETCH_SUCCESS,
  profileData,
});

export const fetchProfileByIdError = (error) => ({
  type: types.PROFILE_BY_ID_FETCH_FAILURE,
  error,
});

export const fetchDeleteProfile = (profileID) => ({
  type: types.DELETE_PROFILE_FETCH,
  profileID,
});

export const fetchDeleteProfileSuccess = () => ({
  type: types.DELETE_PROFILE_FETCH_SUCCESS,
});

export const fetchDeleteProfileError = (error) => ({
  type: types.DELETE_PROFILE_FETCH_FAILURE,
  error,
});

export const changeProfileFormStatus = (status) => ({
  type: types.PROFILE_FORM_STATUS,
  status,
});

export const fetchCurrentContentPositionByProfileClear = () => ({
  type: types.CURRENT_CONTENT_POSITION_BY_PROFILE_FETCH_CLEAR,
});

export const changeEnableProfileOpeartions = (status) => ({
  type: types.ENABLE_PROFILE_OEPRATIONS,
  status,
});

export const changeFormProfileMode = (status) => ({
  type: types.CHANGE_FORM_MODE,
  status,
});

export const fetchFavoriteContentByProfile = (tokenData) => ({
  type: types.FAVORITES_CONTENTS_FETCH,
  tokenData,
});

export const fetchFavoriteContentByProfileSuccess = (contents) => ({
  type: types.FAVORITES_CONTENTS_FETCH_SUCCESS,
  contents,
});

export const fetchFavoriteContentByProfileError = (error) => ({
  type: types.FAVORITES_CONTENTS_FETCH_FAILURE,
  error,
});

export const addFavoriteContentByProfile = (contentID) => ({
  type: types.ADD_FAVORITE_CONTENT_FETCH,
  contentID,
});

export const addFavoriteContentByProfileSuccess = () => ({
  type: types.ADD_FAVORITE_CONTENT_FETCH_SUCCESS,
});

export const addFavoriteContentByProfileError = (error) => ({
  type: types.ADD_FAVORITE_CONTENT_FETCH_FAILURE,
  error,
});

export const deleteFavoriteContentByProfile = (contentID) => ({
  type: types.DELETE_FAVORITE_CONTENT_FETCH,
  contentID,
});

export const deleteFavoriteContentByProfileSuccess = () => ({
  type: types.DELETE_FAVORITE_CONTENT_FETCH_SUCCESS,
});

export const deleteFavoriteContentByProfileError = (error) => ({
  type: types.DELETE_FAVORITE_CONTENT_FETCH_FAILURE,
  error,
});

export const addPinCodeProfile = (targetProfile, pinCode) => ({
  type: types.ADD_PIN_CODE_PROFILE_FETCH,
  targetProfile,
  pinCode,
});

export const addPinCodeProfileSuccess = () => ({
  type: types.ADD_PIN_CODE_PROFILE_FETCH_SUCCESS,
});

export const addPinCodeProfileError = (error) => ({
  type: types.ADD_PIN_CODE_PROFILE_FETCH_FAILURE,
  error,
});

export const editPinCodeProfile = (targetProfile, pinCode) => ({
  type: types.EDIT_PIN_CODE_PROFILE_FETCH,
  targetProfile,
  pinCode,
});

export const editPinCodeProfileSuccess = () => ({
  type: types.EDIT_PIN_CODE_PROFILE_FETCH_SUCCESS,
});

export const editPinCodeProfileError = (error) => ({
  type: types.EDIT_PIN_CODE_PROFILE_FETCH_FAILURE,
  error,
});

export const deletePinCodeProfile = (targetProfile) => ({
  type: types.DELETE_PIN_CODE_PROFILE_FETCH,
  targetProfile,
});

export const deletePinCodeProfileSuccess = () => ({
  type: types.DELETE_PIN_CODE_PROFILE_FETCH_SUCCESS,
});

export const deletePinCodeProfileError = (error) => ({
  type: types.DELETE_PIN_CODE_PROFILE_FETCH_FAILURE,
  error,
});

export const validatePinCodeProfile = (targetProfile, pinCode) => ({
  type: types.VALIDATE_PIN_CODE_PROFILE_FETCH,
  targetProfile,
  pinCode,
});

export const validatePinCodeProfileSuccess = (status) => ({
  type: types.VALIDATE_PIN_CODE_PROFILE_FETCH_SUCCESS,
  status,
});

export const validatePinCodeProfileError = (error) => ({
  type: types.VALIDATE_PIN_CODE_PROFILE_FETCH_FAILURE,
  error,
});

export const enablePinCodeValidateModal = (status, profileIDSelected) => ({
  type: types.ENABLE_VALIDATE_MODAL_PIN_CODE,
  status,
  profileIDSelected,
});

export const loadSelectDataProfile = () => ({
  type: types.LOAD_SELECT_PROFILES,
});

export const setFormDisabledProfile = (status) => ({
  type: types.FORM_DISABLED_PROFILE,
  status,
});

export const updateNotificationTopicsFirebaseToken = (token) => ({
  type: types.UPDATE_PROFILE_NOTIFICATION_TOPICS_TOKEN_FETCH,
  token,
});

export const updateNotificationTopicsFirebaseTokenSuccess = () => ({
  type: types.UPDATE_PROFILE_NOTIFICATION_TOPICS_TOKEN_FETCH_SUCCESS,
});

export const updateNotificationTopicsFirebaseTokenError = (error) => ({
  type: types.UPDATE_PROFILE_NOTIFICATION_TOPICS_TOKEN_FETCH_FAILURE,
  error,
});

export const subscribeToDefaultTopic = (token) => ({
  type: types.SUBSCRIBE_DEAFULT_TOPIC_FETCH,
  token,
});

export const subscribeToDefaultTopicSuccess = () => ({
  type: types.SUBSCRIBE_DEAFULT_TOPIC_FETCH_SUCCESS,
});

export const subscribeToDefaultTopicError = (error) => ({
  type: types.SUBSCRIBE_DEAFULT_TOPIC_FETCH_FETCH_FAILURE,
  error,
});

export const clearStates = () => ({
  type: types.CLEAR_STATES,
});

export const getUserUrns = (accessToken) => ({
  type: types.USER_URNS_FETCH,
  accessToken,
});

export const getUserUrnsSuccess = (userUrns) => ({
  type: types.USER_URNS_FETCH_SUCCESS,
  userUrns,
});

export const getUserUrnsError = (error) => ({
  type: types.USER_URNS_FETCH_FAILAURE,
  error,
});

export const setFavoriteTeam = (team) => ({
  type: types.SET_FAVORITE_TEAM,
  team,
});

export const deleteFavoriteTeam = () => ({
  type: types.DELETE_FAVORITE_TEAM,
});
