import { getFormatedDate } from '@tbx/experience-widgets-lib/src/components/ContentCard/utils';
import {
  CONTENT_TYPES,
  SHOW_ITEMS_BY_DEFAULT,
} from '../constants/contentTypes';

/**
 * Validate if a content has url redirect
 * @param {*} content
 *
 * @return {Boolean}
 */
export const validateUrlRedirect = (content) => {
  return !!content?.currentContent?.entitlementTypes?.find(
    (r) => r === 'redirect',
  );
};

/**
 * Items to display in carousel depending on configuration
 * @param {*} content
 *
 * @return {Boolean}
 */
export const contentItemsToShowInCarousel = (
  maxItems,
  contentListDisplayItems,
) => {
  let countItem = SHOW_ITEMS_BY_DEFAULT.CAROUSEL;
  if (!!contentListDisplayItems && contentListDisplayItems > 0) {
    countItem = contentListDisplayItems;
  } else if (maxItems > 0) {
    countItem = maxItems;
  }

  return countItem;
};

/**
 * Validate if GoBackOnCompletion action if enable depending the contentType
 * @param {*} content
 * @param {*} lastEpisodeId
 *
 * @return {Boolean}
 */
export const validateGoBackOnCompletion = (content, lastEpisodeId) => {
  const { contentType } = content || null;

  if (contentType === CONTENT_TYPES.MOVIE || content.clip) {
    return true;
  }

  if (contentType === CONTENT_TYPES.EPISODE) {
    return content.id === lastEpisodeId;
  }

  return false;
};

/**
 * get the last episode of the serie
 * @param {*} content
 *
 * @return {Boolean}
 */
export const getLastEpisodeInSerie = (content) => {
  const activedEpsiodes = [...content];
  const lastSeason = activedEpsiodes?.slice(-1);
  const lastEpisodeId = lastSeason[0].id;

  return lastEpisodeId;
};

/**
 * Sort episodes of a serie - ASC or DESC. Besides return lastEpisode
 * @param {*} content
 * @param {*} mode
 *
 * @return {Object}
 */
export const sortEpisodes = (content, mode = 'ASC') => {
  if (mode === 'DESC') {
    content?.sort((a, b) => {
      return b?.season - a?.season;
    });
  } else {
    content?.sort((a, b) => {
      return a?.season - b?.season;
    });
  }

  const lastEpisode = getLastEpisodeInSerie(content);

  return { content, lastEpisode };
};

/**
 * Get Epg Channel name
 * @param {*} epgId
 * @param {*} selectedEpgList
 *
 * @return {Object}
 */
export const getEpgChannelName = (epgId, selectedEpgList) => {
  return (
    selectedEpgList && selectedEpgList.find((epg) => epg.epgId === epgId)?.title
  );
};

/**
 * Get Seo Title In PlayerSection
 * @param {*} selectedContent
 * @return {String}
 */
export const getSeoTitleInPlayerSection = (
  selectedContent,
  t,
  lang,
  BRAND_INFO,
) => {
  const date = getFormatedDate({
    date: selectedContent?.flights?.[0]?.flights?.[0]?.start,
    lang,
    hoursString: t('hr'),
  });

  if (selectedContent?.contentType === CONTENT_TYPES.MOVIE) {
    return `${BRAND_INFO.CLIENT_NAME} - ${selectedContent?.title} - Repeticion - ${date}`;
  }

  if (
    selectedContent?.contentType === CONTENT_TYPES.SPORT &&
    selectedContent.live
  ) {
    return `${BRAND_INFO.CLIENT_NAME} - ${selectedContent?.title} - En Vivo - ${date}`;
  }

  return `${BRAND_INFO.CLIENT_NAME} - ${selectedContent?.title} - ${date}`;
};

/**
 * Validate if a enableContentPage is enabled when the array is not empty
 * @param {*} enableContentPage
 *
 * @return {Boolean}
 */
export const isEnableContentPage = (enableContentPage) => {
  return enableContentPage && enableContentPage.length > 0;
};

/**
 * Validate if a contentType is enabled Content Type array
 * @param {*} enableContentPage
 * @param {*} contentType
 *
 * @return {Boolean}
 */
export const isContentInEnableContentPage = (
  enableContentPage,
  contentType,
) => {
  return !!enableContentPage.find((e) => e === contentType);
};

/**
 * Validate if a content is enabled by Property in cloudhub and for its Content Type
 * @param {*} enableContentPage
 * @param {*} content
 *
 * @return {Boolean}
 */
export const validateContentByEnableContentPage = (
  enableContentPage,
  content,
) => {
  return (
    (isEnableContentPage(enableContentPage) &&
      !isContentInEnableContentPage(enableContentPage, content?.contentType) &&
      content.contentType !== CONTENT_TYPES.SERIE) ||
    isLiveContent(content) ||
    content.clip ||
    content.contentType === CONTENT_TYPES.EPISODE
  );
};

/**
 * Validate if a content is live
 * @param {*} content
 *
 * @return {Boolean}
 */
export const isLiveContent = (content) => {
  const { start, end } = content?.flights[0]?.flights[0];
  const now = new Date().getTime();
  const startTime = new Date(start).getTime();
  const endTime = new Date(end).getTime();

  return (
    (content.live && startTime < now && endTime > now) ||
    content.contentType === CONTENT_TYPES.BROADCAST
  );
};

export const getSeoTitleInSection = (
  selectedContent,
  t,
  lang,
  string,
  BRAND_INFO,
) => {
  const date = getFormatedDate({
    date: selectedContent?.flights?.[0]?.flights?.[0]?.start,
    lang,
    hoursString: t('hr'),
  });
  let text = '';

  if (string) {
    text = `- ${string} `;
  }
  return `${BRAND_INFO.CLIENT_NAME} - ${selectedContent?.title} ${text}- ${date}`;
};

/**
 * Validate if a user has urn for content
 * @param {*} contentUrn
 * @param {*} userUrns
 *
 * @return {Boolean}
 */
export const validateAccessToContentByUrns = (contentUrn, userUrns) => {
  return userUrns && contentUrn && userUrns.includes(contentUrn);
};
