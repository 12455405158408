export default [
  { value: 'Alianza FC', label: 'Alianza FC' },
  { value: 'America de Cali', label: 'América de Cali' },
  { value: 'Atletico Bucaramanga', label: 'Atlético Bucaramanga' },
  { value: 'Atletico FC', label: 'Atlético FC' },
  { value: 'Atletico Huila', label: 'Atlético Huila' },
  { value: 'Atletico Nacional', label: 'Atlético Nacional' },
  { value: 'Barranquilla FC', label: 'Barranquilla FC' },
  { value: 'Boca Juniors de Cali', label: 'Boca Juniors de Cali' },
  { value: 'Bogota FC', label: 'Bogotá FC' },
  { value: 'Boyaca Chico', label: 'Boyacá Chicó' },
  { value: 'Cucuta Deportivo', label: 'Cúcuta Deportivo' },
  { value: 'Deportes Quindio', label: 'Deportes Quindio' },
  { value: 'Deportes Tolima', label: 'Deportes Tolima' },
  { value: 'Deportivo Cali', label: 'Deportivo Cali' },
  { value: 'Deportivo Pasto', label: 'Deportivo Pasto' },
  { value: 'Deportivo Pereira', label: 'Deportivo Pereira' },
  { value: 'Envigado', label: 'Envigado' },
  { value: 'Fortaleza CEIF', label: 'Fortaleza CEIF' },
  { value: 'Independiente Medellin', label: 'Independiente Medellín' },
  { value: 'Independiente Santa Fe', label: 'Independiente Santa Fe' },
  {
    value: 'Internacional FC de Palmira',
    label: 'Internacional FC de Palmira',
  },
  { value: 'Jaguares de Cordoba', label: 'Jaguares de Córdoba' },
  { value: 'Junior', label: 'Junior' },
  { value: 'La Equidad', label: 'La Equidad' },
  { value: 'Leones FC', label: 'Leones FC' },
  { value: 'Llaneros FC', label: 'Llaneros FC' },
  { value: 'Millonarios', label: 'Millonarios' },
  { value: 'Once Caldas', label: 'Once Caldas' },
  { value: 'Orsomarso SC', label: 'Orsomarso SC' },
  { value: 'Patriotas', label: 'Patriotas' },
  { value: 'Real Cartagena', label: 'Real Cartagena' },
  { value: 'Real Cundinamarca', label: 'Real Cundinamarca' },
  { value: 'Real Santander', label: 'Real Santander' },
  { value: 'Tigres FC', label: 'Tigres FC' },
  { value: 'Union Magdalena', label: 'Unión Magdalena' },
  { value: 'Aguilas Doradas', label: 'Águilas Doradas' },
];
