import {
  unityContentsService,
  unityProfileService,
} from '@tbx/experience-widgets-lib';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import { CONTENT_TYPES, types } from './constants';
import * as selectors from './selectors';

function* fetchContentWidget(action) {
  const { accessToken, contentID } = action;

  try {
    const { result } = yield call(
      unityContentsService.getContentsById,
      accessToken,
      contentID,
    );

    if (!result || result.error) {
      throw new Error(result.error);
    }

    yield put(actions.loadWidgetSuccess(result));
  } catch (e) {
    console.error('CONTENTOVERVIEW_SECTION_WIDGET_FETCH_ERROR: ', e);
    yield put(actions.loadWidgetError(e));
  }
}

function* fetchContentEpisodesAndContentRelatedWidget(action) {
  if (!action.season && action.content.seasons.length > 0) {
    action.season = action.content.seasons[0];
  } else if (
      action.season &&
      action.content.seasons.length > 0 &&
      !action.content.seasons.includes(action.season)
  ) {
    action.season = action.content.seasons[0];
  }
  const { content, page = 1, pageSize = 20, season = 1 } = action;

  try {
    const currentToken = yield select((state) =>
      selectors.selectAccessToken(state),
    );

    const contentTypes = content.contentType?.toUpperCase() ?? '';
    let dataResult;
    if (contentTypes === 'SERIE') {
      dataResult = yield call(
        CONTENT_TYPES[content.contentType].SERVICE,
        currentToken,
        content.id,
        {
          contentType: contentTypes,
          page,
          pageSize,
          season: Number(season),
        },
      );
    } else {
      dataResult = yield call(
        CONTENT_TYPES[content.contentType].SERVICE,
        currentToken,
        content.id,
        {
          contentType: contentTypes,
        },
      );
    }

    if (!dataResult || dataResult.error) {
      throw new Error(dataResult.error);
    }

    const episodes =
      dataResult?.result?.result !== null ? dataResult?.result?.result : [];
    const totalEpisodes =
      dataResult?.result?.total !== null ? dataResult?.result?.total : 0;

    const currentEpisodes = yield select((state) =>
      selectors.selectContentEpisodesWidget(state),
    );

    const mergedEpisodes =
      page === 1 ? episodes : [...currentEpisodes, ...episodes];

    yield put(actions.totalEpisodes(totalEpisodes));

    yield put(
      actions[CONTENT_TYPES[content.contentType].LOAD_ACTION](mergedEpisodes),
    );
  } catch (e) {
    console.error('CONTENTEPISODES_WIDGET_FETCH_ERROR: ', e);
    yield put(actions.loadWidgetError(e));
  }
}

function* fetchEpisodeCurrentContentEpisodeByProfile(action) {
  const { accessToken, contentID, profileID } = action;

  try {
    const { result } = yield call(
      unityProfileService.getContentByProfile,
      accessToken,
      contentID,
      profileID,
    );

    if (!result || result.error) {
      throw new Error(result.error);
    }

    yield put(actions.getEpisodeCurrentContentByProfileSuccess(result));
  } catch (e) {
    console.error(types.EPISODE_CURRENT_CONTENT_PROFILE_FETCH_FAILURE, e);
    yield put(actions.getEpisodeCurrentContentByProfileError(e));
  }
}

function* fetchContensUrlById(action) {
  const { accessToken, contentID } = action;

  try {
    const { result } = yield call(
      unityContentsService.getContentsUrlById,
      accessToken,
      contentID,
      {},
    );

    if (!result || result.error) {
      throw new Error(result.error);
    }

    yield put(actions.getContentsUrlByIdSuccess(result));
  } catch (e) {
    console.error('CONTENTOVERVIEW_SECTION_CONTENTS_URL_FETCH_ERROR: ', e);
    yield put(actions.getContentsUrlByIdError(e));
  }
}

function* saga() {
  yield takeLatest(types.WIDGET_FETCH, fetchContentWidget);
  yield takeLatest(
    types.WIDGET_FETCH_SUCCESS,
    fetchContentEpisodesAndContentRelatedWidget,
  );
  yield takeLatest(
    types.EPISODE_CURRENT_CONTENT_PROFILE_FETCH,
    fetchEpisodeCurrentContentEpisodeByProfile,
  );
  yield takeLatest(types.CONTENTS_URL_ID_FETCH, fetchContensUrlById);
}

export default saga;
