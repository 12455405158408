import PATHS from './pathRoutes';

export const MOBILE_ENABLED_PATHS = [
  PATHS.login,
  PATHS.active,
  PATHS.privacy,
  PATHS.termAndUses,
  PATHS.help,
  PATHS.mobileLading,
];
