import {
    isWithinInterval,
    setMinutes,
    setHours,
    setSeconds,
} from 'date-fns';

export const isWithinSchedule = (schedule) => {
    if (!schedule?.start && !schedule?.end) {
        // There is not a start date and there is not an end date
        // wich means there is not schedule restrictions so true is returned
        return true;
    } else if (!schedule?.start) {
        // if you reached this point means there is a schedule end date but there is not a schedule start date
    } else if (!schedule?.end) {
        // if you reached this point means there is a schedule start date but there is not a schedule end date
    } else {
        // if you reached this point means there is a schedule start date and there is a schedule end date
        const nowTimeZulu = setHours(new Date(), new Date().getUTCHours());
        const [startingHours, startingMinutes] = schedule.start.split(':').map(Number);
        const [endingHours, endingMinutes] = schedule.end.split(':').map(Number);

        const startSchedule = setHours(
            setMinutes(setSeconds(new Date(), 0), startingMinutes),
            startingHours
        );

        const endSchedule = setHours(
            setMinutes(setSeconds(new Date(), 0), endingMinutes),
            endingHours
        );
        return isWithinInterval(nowTimeZulu, {
            start: startSchedule,
            end: endSchedule,
        });
    }
};

