export const types = {
  AVATARS_FETCH: 'ProfileManager/AVATARS_FETCH',
  AVATARS_FETCH_FAILURE: 'ProfileManager/AVATARS_FETCH_FAILURE',
  AVATARS_FETCH_SUCCESS: 'ProfileManager/AVATARS_FETCH_SUCCESS',
  PROFILES_FETCH: 'ProfileManager/PROFILES_FETCH',
  PROFILES_FETCH_FAILURE: 'ProfileManager/PROFILES_FETCH_FAILURE',
  PROFILES_FETCH_SUCCESS: 'ProfileManager/PROFILES_FETCH_SUCCESS',
  SET_CURRENT_PROFILE_FETCH: 'ProfileManager/SET_CURRENT_PROFILE_FETCH',
  SET_CURRENT_PROFILE_FETCH_SUCCESS:
    'ProfileManager/SET_CURRENT_PROFILE_FETCH_SUCCESS',
  SET_CURRENT_PROFILE_FAILURE: 'ProfileManager/SET_CURRENT_PROFILE_FAILURE',
  CURRENT_CONTENT_POSITION_BY_PROFILE_FETCH:
    'ProfileManager/CURRENT_CONTENT_POSITION_BY_PROFILE_FETCH',
  CURRENT_CONTENT_POSITION_BY_PROFILE_FETCH_FAILURE:
    'ProfileManager/CURRENT_CONTENT_POSITION_BY_PROFILE_FETCH_FAILURE',
  CURRENT_CONTENT_POSITION_BY_PROFILE_FETCH_SUCCESS:
    'ProfileManager/CURRENT_CONTENT_POSITION_BY_PROFILE_FETCH_SUCCESS',
  CREATE_PROFILE_FETCH: 'ProfileManager/CREATE_PROFILE_FETCH',
  CREATE_PROFILE_FETCH_SUCCESS: 'ProfileManager/CREATE_PROFILE_FETCH_SUCCESS',
  CREATE_PROFILE_FETCH_FAILURE: 'ProfileManager/CREATE_PROFILE_FETCH_FAILURE',
  EDIT_PROFILE_FETCH: 'ProfileManager/EDIT_PROFILE_FETCH',
  EDIT_PROFILE_FETCH_SUCCESS: 'ProfileManager/EDIT_PROFILE_FETCH_SUCCESS',
  EDIT_PROFILE_FETCH_FAILURE: 'ProfileManager/EDIT_PROFILE_FETCH_FAILURE',
  PROFILE_BY_ID_FETCH: 'ProfileManager/PROFILE_BY_ID_FETCH',
  PROFILE_BY_ID_FETCH_SUCCESS: 'ProfileManager/PROFILE_BY_ID_FETCH_SUCCESS',
  PROFILE_BY_ID_FETCH_FAILURE: 'ProfileManager/PROFILE_BY_ID_FETCH_FAILURE',
  DELETE_PROFILE_FETCH: 'ProfileManager/DELETE_PROFILE_FETCH',
  DELETE_PROFILE_FETCH_SUCCESS: 'ProfileManager/DELETE_PROFILE_FETCH_SUCCESS',
  DELETE_PROFILE_FETCH_FAILURE: 'ProfileManager/DELETE_PROFILE_FETCH_FAILURE',
  PROFILE_FORM_STATUS: 'ProfileManager/PROFILE_FORM_STATUS',
  CURRENT_CONTENT_POSITION_BY_PROFILE_FETCH_CLEAR:
    'ProfileManager/CURRENT_CONTENT_POSITION_BY_PROFILE_FETCH_CLEAR',
  ENABLE_PROFILE_OEPRATIONS: 'ProfileManager/ENABLE_PROFILE_OEPRATIONS',
  CHANGE_FORM_MODE: 'ProfileManager/CHANGE_FORM_MODE',
  ADD_FAVORITE_CONTENT_FETCH: 'ProfileManager/ADD_FAVORITE_CONTENT_FETCH',
  ADD_FAVORITE_CONTENT_FETCH_SUCCESS:
    'ProfileManager/ADD_PROFILE_FETCH_SUCCESS',
  ADD_FAVORITE_CONTENT_FETCH_FAILURE:
    'ProfileManager/ADD_PROFILE_FETCH_FAILURE',
  DELETE_FAVORITE_CONTENT_FETCH: 'ProfileManager/DELETE_FAVORITE_CONTENT_FETCH',
  DELETE_FAVORITE_CONTENT_FETCH_SUCCESS:
    'ProfileManager/DELETE_PROFILE_FETCH_SUCCESS',
  DELETE_FAVORITE_CONTENT_FETCH_FAILURE:
    'ProfileManager/DELETE_PROFILE_FETCH_FAILURE',
  FAVORITES_CONTENTS_FETCH: 'ProfileManager/FAVORITES_CONTENTS_FETCH',
  FAVORITES_CONTENTS_FETCH_SUCCESS:
    'ProfileManager/FAVORITES_CONTENTS_FETCH_SUCCESS',
  FAVORITES_CONTENTS_FETCH_FAILURE:
    'ProfileManager/FAVORITES_CONTENTS_FETCH_FAILURE',
  ADD_PIN_CODE_PROFILE_FETCH: 'ProfileManager/ADD_PIN_CODE_PROFILE_FETCH',
  ADD_PIN_CODE_PROFILE_FETCH_SUCCESS:
    'ProfileManager/ADD_PIN_CODE_PROFILE_FETCH_SUCCESS',
  ADD_PIN_CODE_PROFILE_FETCH_FAILURE:
    'ProfileManager/ADD_PIN_CODE_PROFILE_FETCH_FAILURE',
  EDIT_PIN_CODE_PROFILE_FETCH: 'ProfileManager/EDIT_PIN_CODE_PROFILE_FETCH',
  EDIT_PIN_CODE_PROFILE_FETCH_SUCCESS:
    'ProfileManager/EDIT_PIN_CODE_PROFILE_FETCH_SUCCESS',
  EDIT_PIN_CODE_PROFILE_FETCH_FAILURE:
    'ProfileManager/EDIT_PIN_CODE_PROFILE_FETCH_FAILURE',
  DELETE_PIN_CODE_PROFILE_FETCH: 'ProfileManager/DELETE_PIN_CODE_PROFILE_FETCH',
  DELETE_PIN_CODE_PROFILE_FETCH_SUCCESS:
    'ProfileManager/DELETE_PIN_CODE_PROFILE_FETCH_SUCCESS',
  DELETE_PIN_CODE_PROFILE_FETCH_FAILURE:
    'ProfileManager/DELETE_PIN_CODE_PROFILE_FETCH_FAILURE',
  VALIDATE_PIN_CODE_PROFILE_FETCH:
    'ProfileManager/VALIDATE_PIN_CODE_PROFILE_FETCH',
  VALIDATE_PIN_CODE_PROFILE_FETCH_SUCCESS:
    'ProfileManager/VALIDATE_PIN_CODE_PROFILE_FETCH_SUCCESS',
  VALIDATE_PIN_CODE_PROFILE_FETCH_FAILURE:
    'ProfileManager/VALIDATE_PIN_CODE_PROFILE_FETCH_FAILURE',
  ENABLE_VALIDATE_MODAL_PIN_CODE:
    'ProfileManager/ENABLE_VALIDATE_MODAL_PIN_CODE',
  LOAD_SELECT_PROFILES: 'ProfileManager/LOAD_SELECT_PROFILES',
  FORM_DISABLED_PROFILE: 'ProfileManager/FORM_DISABLED_PROFILE',
  UPDATE_PROFILE_NOTIFICATION_TOPICS_TOKEN_FETCH:
    'ProfileManager/UPDATE_PROFILE_NOTIFICATION_TOPICS_TOKEN_FETCH',
  UPDATE_PROFILE_NOTIFICATION_TOPICS_TOKEN_FETCH_SUCCESS:
    'ProfileManager/UPDATE_PROFILE_NOTIFICATION_TOPICS_TOKEN_FETCH_SUCCESS',
  UPDATE_PROFILE_NOTIFICATION_TOPICS_TOKEN_FETCH_FAILURE:
    'ProfileManager/UPDATE_PROFILE_NOTIFICATION_TOPICS_TOKEN_FETCH_FAILURE',
  SUBSCRIBE_DEAFULT_TOPIC_FETCH: 'ProfileManager/SUBSCRIBE_DEAFULT_TOPIC_FETCH',
  SUBSCRIBE_DEAFULT_TOPIC_FETCH_SUCCESS:
    'ProfileManager/SUBSCRIBE_DEAFULT_TOPIC_FETCH_SUCCESS',
  SUBSCRIBE_DEAFULT_TOPIC_FETCH_FETCH_FAILURE:
    'ProfileManager/SUBSCRIBE_DEAFULT_TOPIC_FETCH_FETCH_FAILURE',
  CLEAR_STATES: 'ProfileManager/CLEAR_STATES',
  USER_URNS_FETCH: 'ProfileManager/USER_URNS_FETCH',
  USER_URNS_FETCH_SUCCESS: 'ProfileManager/USER_URNS_FETCH_SUCCESS',
  USER_URNS_FETCH_FAILAURE: 'ProfileManager/USER_URNS_FETCH_FAILAURE',
  SET_FAVORITE_TEAM: 'SET_FAVORITE_TEAM',
  DELETE_FAVORITE_TEAM: 'DELETE_FAVORITE_TEAM',
};

export const MAX_RATING_DEFAULT = {
  TODDLER: '0',
  KID: '1',
  TEENAGER: '2',
  ADULT: '3',
  ADULT_XXX: '4',
};

export const PROFILE_TYPES = [
  {
    name: 'Toddler',
    max_rating: MAX_RATING_DEFAULT.TODDLER,
  },
  {
    name: 'Kid',
    max_rating: MAX_RATING_DEFAULT.KID,
  },
  {
    name: 'Teenager',
    max_rating: MAX_RATING_DEFAULT.TEENAGER,
  },
  {
    name: 'Adult',
    max_rating: MAX_RATING_DEFAULT.ADULT,
  },
  {
    name: 'Adult xxx',
    max_rating: MAX_RATING_DEFAULT.ADULT_XXX,
  },
];

export const NUMBER_FIELDS_PIN = {
  DEFAULT: 4,
  CUSTOM: 4,
};
