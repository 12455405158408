import React from 'react';
import { useSelector } from 'react-redux';

// #region ///// COMPONENTS /////
import AvatarOption from '../../components/AvatarOption';
import ProfileOption from '../../components/Profile/ProfileOption';
// #endregion

import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Avatar from '../../components/Avatar';
import LoadingSpinner from '../../components/LoadingSpinner';
import NotificationModal from '../../components/NotificationModal';
import ProfileForm from '../../components/Profile/ProfileForm';
import ProfilePinCode from '../../components/Profile/ProfilePinCode';
import {
  countProfileTypes,
  getAvatarInCollectionById,
  getCurrentProfileData,
  getProfilesWithOutDefault,
} from '../../utils/ProfileUtils';
import { selectNotificationModal } from '../App/selectors';
import * as hooks from './hooks';
import * as selectors from './selectors';
import './styles.scss';
import useClientConfigs from '../../customHooks/useClientConfigs';

function ProfileManager(props) {
  const { history } = props;
  const { t } = useTranslation();
  const { enableCustomTags } = useClientConfigs();
  // #region /////// SELECTORS ///////
  const avatarCollection = useSelector((state) =>
    selectors.selectAvatarCollection(state),
  );
  const isFetching = useSelector((state) => selectors.selectIsFetching(state));
  const profileCollection = useSelector((state) =>
    selectors.selectProfileCollection(state),
  );
  const {
    maxProfiles,
    suggestedProfileRatingLevel,
    parentalControl,
    publicProfileRatingLevel,
  } = useSelector((state) => selectors.selectAppSettings(state));
  const profileDataFetch = useSelector((state) =>
    selectors.selectProfileFormData(state),
  );
  const profileformStatus = useSelector((state) =>
    selectors.selectProfileFormStatus(state),
  );
  const enableProfileOperations = useSelector((state) =>
    selectors.selectEnableProfileOperations(state),
  );
  const profileSelected = useSelector((state) =>
    selectors.selectProfileSelected(state),
  );
  const isAddFormMode = useSelector((state) =>
    selectors.selectFormModeProfile(state),
  );
  const notificationModal = useSelector((state) =>
    selectNotificationModal(state),
  );
  const enablePinCodeValidationModal = useSelector((state) =>
    selectors.selectEnablePinCodeValidationModal(state),
  );
  const validatePinCodeStatus = useSelector((state) =>
    selectors.selectValidatePinCodeStatus(state),
  );
  const disabledForm = useSelector((state) =>
    selectors.selectFormDisabledStatus(state),
  );
  const PATHS = useSelector((state) => selectors.selectPathList(state));
  const goBackFunction = () => {
    const { origin } = window.location;
    history.push(PATHS.home, { returnURL: origin });
    history.go();
  };
  // #endregionse

  const currentDataProfileSelected = getCurrentProfileData(
    profileCollection,
    profileSelected,
  );

  // #region ///// Hook Effects /////
  hooks.useUnityProfiles(profileformStatus);
  const {
    enableAvatarOption,
    profileDataForm,
    pinCodeValue,
    handleOnSelectProfile,
    handleAvatarEdit,
    handleOnChangeProfileNameForm,
    handleCancel,
    handleSubmit,
    handleProfileTypeSelected,
    handleOnAddProfile,
    handleOnEditProfile,
    handleConfirmDeleteProfile,
    handleAvatarOption,
    handleAvatarBackButton,
    handlePinCodeChange,
    handlePinCodeSubmit,
    handlePinCodeCancel,
    handleDeletePinCode,
    handleProfilePinCodeLogout,
    handleFavoriteTeamSelected
  } = hooks.useContentProfile(
    profileDataFetch,
    profileformStatus,
    isAddFormMode,
    currentDataProfileSelected,
    history,
    publicProfileRatingLevel,
  );
  // #endregion

  const { props: profileProps } = profileDataForm;
  const { avatarId } = profileProps;
  const profileCollectionSize = profileCollection?.length;
  const avatarSelected = getAvatarInCollectionById(avatarCollection, avatarId);
  const profilesWithoutDefault = getProfilesWithOutDefault(profileCollection);

  if (notificationModal) {
    return <NotificationModal notificationModal={notificationModal} />;
  }

  return (
    <section className="profile-manager">
      <header className="profile-manager_header">
        {enableAvatarOption && (
          <div className="profile-manager_header__button">
            <Button
              size="sm"
              className="avatarOption_backButtom"
              onClick={(e) => handleAvatarBackButton(e)}
            >
              <i className="bi bi-arrow-left-short"></i>
            </Button>
          </div>
        )}
      </header>

      <div
        className={`profile-manager_animation ${
          disabledForm ? 'disabledForm' : ''
        }`}
      >
        {isFetching ? (
          <div className={'profile-manager_container_loadingSpinner'}>
            <LoadingSpinner className={'profile-manager_loadingSpinner'} />
          </div>
        ) : (
          <React.Fragment>
            {enablePinCodeValidationModal ? (
              <div className="profile-manager_form">
                <ProfilePinCode
                  handlePinCodeChange={handlePinCodeChange}
                  handlePinCodeSubmit={handlePinCodeSubmit}
                  handlePinCodeCancel={handlePinCodeCancel}
                  isFetching={isFetching}
                  validatePinCodeStatus={validatePinCodeStatus}
                  pinCodeValue={pinCodeValue}
                  handleProfilePinCodeLogout={handleProfilePinCodeLogout}
                />
              </div>
            ) : enableAvatarOption ? (
              <AvatarOption
                avatarCollection={avatarCollection}
                handleAvatarEdit={handleAvatarEdit}
                handleAvatarBackButton={handleAvatarBackButton}
              />
            ) : !profileformStatus ? (
              <ProfileOption
                handleOnAddProfile={handleOnAddProfile}
                handleOnEditProfile={handleOnEditProfile}
                handleConfirmDeleteProfile={handleConfirmDeleteProfile}
                handleOnSelectProfile={handleOnSelectProfile}
                avatarCollection={avatarCollection}
                profileCollection={profileCollection}
                maxProfiles={maxProfiles}
                enableProfileOperations={enableProfileOperations}
                currentDataProfileSelected={currentDataProfileSelected}
                profileCollectionSize={profileCollectionSize}
                profilesWithoutDefault={profilesWithoutDefault}
                handleGoBack={goBackFunction}
              />
            ) : (
              <div className="profile-manager_form">
                <div>
                  <h1 className="profile-manager__title">
                    {isAddFormMode ? t('Add profile') : t('Edit profile')}
                  </h1>
                </div>
                <Avatar
                  avatarData={avatarId ? avatarSelected : avatarCollection[0]}
                  handleAvatarOption={handleAvatarOption}
                  className={'profileManagerForm_avatar'}
                />
                <ProfileForm
                  isAddMode={isAddFormMode}
                  handleOnChangeProfileNameForm={(e) =>
                    handleOnChangeProfileNameForm(e)
                  }
                  handleCancel={(e) => handleCancel(e)}
                  handleSubmit={(e) => handleSubmit(e)}
                  handleProfileTypeSelected={handleProfileTypeSelected}
                  profileDataForm={profileDataForm}
                  currentDataProfileSelected={currentDataProfileSelected}
                  profileCollectionSize={profileCollectionSize}
                  handlePinCodeChange={handlePinCodeChange}
                  handleDeletePinCode={handleDeletePinCode}
                  pinCodeValue={pinCodeValue}
                  profileCounts={countProfileTypes(profileCollection)}
                  suggestedProfileRatingLevel={suggestedProfileRatingLevel}
                  parentalControl={parentalControl}
                  enableCustomTags={enableCustomTags}
                  handleFavoriteTeamSelected={handleFavoriteTeamSelected}
                />
              </div>
            )}
          </React.Fragment>
        )}
        {/* <button className="profile-manager_editbutton">
          {t('Manage Profiles')}
        </button> */}
      </div>
    </section>
  );
}

export default ProfileManager;
