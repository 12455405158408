export const types = {
  CONTENT_SELECTED: 'PlayerSection/CONTENT_SELECTED',
  WIDGET_FETCH: 'PlayerSection/WIDGET_FETCH',
  WIDGET_FETCH_FAILURE: 'PlayerSection/WIDGET_FETCH_FAILURE',
  WIDGET_FETCH_SUCCESS: 'PlayerSection/WIDGET_FETCH_SUCCESS',
  CLEAR_STATES: 'PlayerSection/CLEAR_STATES',
  CLEAR_IS_NEXT_EPISODE_PLAYER: 'PlayerSection/CLEAR_IS_NEXT_EPISODE_PLAYER',
  START_NEXT_EPISODE: 'PlayerSection/START_NEXT_EPISODE',
  START_NEXT_EPISODE_SUCCESS: 'PlayerSection/START_NEXT_EPISODE_SUCCESS',
  START_NEXT_EPISODE_FAILURE: 'PlayerSection/START_NEXT_EPISODE_FAILURE',
};

export const TITLES_MODAL = {
  'CL-001': {
    title: '¡Sorry!',
    message: 'Concurrence denied by server.',
    firstButton: 'Continue',
  },
  'CL-002': {
    title: 'VIDEO PLAYER',
    message: 'No connection to internet.',
    firstButton: 'Continue',
  },
  'CP-006': {
    title: 'VIDEO PLAYER',
    message: 'User disconnected.',
    firstButton: 'Continue',
  },
  'CS-011': {
    title: '¡Sorry!',
    message:
      'You do not have the necessary permissions to view the selected content.',
    firstButton: 'Hire now',
    secondButton: 'Continue',
  },
  'SE-003': {
    title: 'VIDEO PLAYER',
    message:
      'Warning: Sorry, we are experiencing technical issues. Please try again later.',
  },
  'CP-015': {
    title: 'VIDEO PLAYER',
    message: 'An error has occurred on the site: CP-015. Please, try again.',
  },
  'CP-037': {
    title: 'You are not logged in',
    message: 'Try to login again',
    firstButton: 'Continue',
  },
  'CP-137': {
    title: 'You are not logged in',
    message: 'Try to login again',
    firstButton: 'Continue',
  },
  'GE-001': {
    title: 'Ahgggg! Huston we have a problem...',
    message:
      'This content is  no longer available on the actual region at this moment',
    firstButton: 'Continue',
  },
  4: {
    title: 'VIDEO PLAYER',
    message:
      'Warning: Sorry, we are experiencing technical issues. Please try again later.',
  },
  DEFAULT: {
    title: 'VIDEO PLAYER',
    message:
      'Warning: Sorry, we are experiencing technical issues. Please try again later.',
  },
  2: {
    title: 'VIDEO PLAYER',
    message: 'This content is not available at the moment',
  },
};

export const TITLES = [
  'Next episode in',
  'Season',
  'Episode',
  'Seconds',
  'Audio',
  'Subtitles',
  'Inactivity modal title',
  'Inactivity modal cancel',
  'Inactivity modal confirm',
];

export const LIVE_PLAYER = 'Live';
